<section [formGroup]="energyTypeFormGroup">
  <div>
    <h3 class="font-medium text-lg">
      {{ 'pages.move.energyType.myEnergies' | translate }}
    </h3>
    <div
      *ngFor="let deliveryPoint of deliveryPoints"
      class="flex rounded-4xl bg-grey-light mt-4 p-8 border border-silvermist items-center"
    >
      <img
        [src]="'assets/img/icons/standalone/default/' + deliveryPoint?.energyType.toLocaleLowerCase() + '.svg'"
        class="inline-block mr-2"
        width="22"
        height="25"
        [alt]="deliveryPoint?.energyType"
      />
      <div class="pl-4">
        {{ 'pages.move.energyType.energy.' + deliveryPoint?.energyType.toLocaleLowerCase() | translate }}
      </div>
    </div>
  </div>

  <div class="mt-12">
    <h3 class="font-medium text-lg mb-4">{{ 'pages.move.energyType.startingDate' | translate }}</h3>
    <date-picker-field
      formControlName="date"
      [fieldLabel]="'JJ/MM/AAAA'"
      [dateRange]="serviceDateRange"
      (dateChanged)="onDateSelection($event)"
    >
    </date-picker-field>
    <div
      *ngIf="
        energyTypeFormGroup.get('date').invalid &&
        (energyTypeFormGroup.get('date').touched || energyTypeFormGroup.get('date').dirty)
      "
      class="error inline-block w-full text-primary text-sm"
    >
      {{ 'errorMessages.requiredField' | translate }}
    </div>
  </div>
</section>
