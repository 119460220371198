import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoMoveComponent } from '@app/modules/customer-zone/move/components/move-status/views/no-move/no-move.component';
import { MoveSummaryComponent } from '@app/modules/customer-zone/move/components/move-status/views/move-summary/move-summary.component';
import { SiteStatusCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { ViewStatus } from '../../models/status.interface';
import { translate } from '@angular/localize/tools';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-move-status',
  standalone: true,
  imports: [CommonModule, TranslateModule, NoMoveComponent, MoveSummaryComponent],
  templateUrl: './move-status.component.html',
  styleUrls: ['./move-status.component.scss'],
})
export class MoveStatusComponent implements OnInit {
  @Input() moveSites: SiteStatusCuzoApi[];
  readonly ViewStatus = ViewStatus;

  constructor() {}

  ngOnInit(): void {}
}
