import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-giveaway-banner',
  templateUrl: './giveaway-banner.component.html',
  styleUrls: ['./giveaway-banner.component.scss'],
})
export class GiveawayBannerComponent {
  @Input() visibility: boolean = true;
}
