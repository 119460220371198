import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MainFacade } from '@app/core/facade/main.facade';
import { catchError, filter, forkJoin, iif, map, Observable, of, switchMap, take, tap, timer } from 'rxjs';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { INITIAL_MOVE_STATE, MoveDTO, MoveMeter, MoveRegister, MoveState } from '@app/core/state/move.state';
import { PreSwitchLight, SwitchPossibility, SwitchType } from '@app/modules/customer-zone/move/models/api.interface';
import moment from 'moment';
import { DreDocumentComponent } from '@app/modules/customer-zone/move/components/move-form/parts/dre-document/dre-document.component';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateRangeValidator } from '@app/shared/date-picker-field/validators';
import { DeliveryPoint, MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { ORDERED_QUESTION_STAY } from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { BackendToFrontendMapperService } from '@app/modules/customer-zone/move/services/mapper/backend-to-frontend-mapper.service';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-moving-info',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NavigationComponent,
    ReactiveFormsModule,
    TranslateModule,
    DatePickerFieldModule,
    AlertComponent,
    DreDocumentComponent,
  ],
  templateUrl: './moving-info.component.html',
  styleUrls: ['./moving-info.component.scss'],
})
export class MovingInfoComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  readonly AlertType = AlertType;
  form: FormGroup;
  serviceDateRange$: Observable<{ min: NgbDateStruct; max: NgbDateStruct }>;
  hasMissingMandatoryIndexes: boolean = false;
  fileUploadInProgress: boolean = false;
  fileName: string;
  private moveDTO: MoveDTO;
  submittedForm: boolean = false;

  constructor(private readonly facade: MainFacade, protected moveFormFacade: MoveFormFacade, private fb: FormBuilder) {
    super(moveFormFacade);
  }

  ngOnInit(): void {
    this.buildForm();
    this.setFormValues();
    this.form
      .get('dreStatus')
      .valueChanges.subscribe((dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi): void => {
        this.serviceDateRange$ = this.setServiceDateRange(dreStatus);
      });

    // set billing info in move object
    forkJoin([
      this.moveFormFacade.state$.pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      ),
      this.facade.loadBillingDetails(null, false).pipe(take(1)),
    ]).subscribe(([state, billingDetails]) => {
      state.moveDTO.billingInfo = BackendToFrontendMapperService.mapReadInvoiceDataToBillingInfo(billingDetails);
      this.moveFormFacade.update(state.moveDTO).subscribe();
    });
  }

  buildForm() {
    this.form = this.fb.group({
      dreStatus: new FormControl<UploadDocumentParamsDreCompletionStatusEnumCuzoApi>(null, [Validators.required]),
      fileForPartialStatus: [null, [Validators.required]],
      fileForFullStatus: [null, [Validators.required]],
      movingDate: [null, [Validators.required]],
    });
  }

  setFormValues() {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state: MoveState) => {
        this.moveDTO = state?.moveDTO;
        const formValues = state?.form?.movingInfo;
        this.fileName = formValues?.fileName;

        this.form.patchValue({
          dreStatus: formValues?.dreStatus,
          fileForPartialStatus:
            formValues?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL
              ? this.moveFormFacade.createEmptyFile(formValues?.fileName)
              : null,
          fileForFullStatus:
            formValues?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL
              ? this.moveFormFacade.createEmptyFile(formValues?.fileName)
              : null,
          movingDate: formValues?.movingDate,
        });
        if (formValues?.movingDate) {
          this.hasMissingMandatoryIndexes = this.checkForMissingMandatoryIndexes(this.moveDTO, formValues?.movingDate);
        }
        if (formValues?.dreStatus) {
          this.serviceDateRange$ = this.setServiceDateRange(formValues?.dreStatus);
        }
      });
  }

  onDateSelection(date: string): void {
    this.form.get('movingDate').setValue(date);
    const moveDTO: MoveDTO = this.moveFormFacade.state$.value.moveDTO;
    this.hasMissingMandatoryIndexes = this.checkForMissingMandatoryIndexes(moveDTO, date);
    this.saveFormData(true);
  }

  onNextClickedDefault(): void {
    this.submittedForm = true;
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData()
        .pipe(take(1))
        .subscribe((registration: MoveInRegistration) => {
          if (registration !== null) {
            this.moveFormFacade.updateData({ registration });
          }
          this.moveFormFacade.next();
          this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        });
    } else {
      markFormGroupTouched(this.form);
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getLocalForm() });
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  handleMoveIn(moveDTO: MoveDTO) {
    const state: MoveState = this.moveFormFacade.state$.value;
    return !!moveDTO?.moveChainId || !!state?.registration
      ? this.moveFormFacade.getRegistration(moveDTO.id).pipe(
          tap((registration) =>
            this.moveFormFacade.updateData({
              registration,
              form: BackendToFrontendMapperService.map(moveDTO, registration),
            })
          )
        )
      : this.moveFormFacade.setAmendment(moveDTO);
  }

  remainTotalEnergiesClient() {
    const state: MoveState = this.moveFormFacade.state$.value;
    return JSON.stringify(state.route) === JSON.stringify(ORDERED_QUESTION_STAY);
  }

  onFileSelection(file: File) {
    if (this.form.get('fileForPartialStatus').valid || this.form.get('fileForFullStatus').valid) {
      this.fileUploadInProgress = true;

      this.moveFormFacade
        .uploadMoveOutDREFile(
          this.facade.state$.value.reference,
          file,
          this.form.get('dreStatus').value as UploadDocumentParamsDreCompletionStatusEnumCuzoApi
        )
        .pipe(
          take(1),
          catchError((error) => {
            this.fileUploadInProgress = false;
            return error;
          })
        )
        .subscribe(() => (this.fileUploadInProgress = false));
    }
  }

  saveFormData(saveForLater: boolean = false): Observable<MoveInRegistration> {
    this.moveDTO.ilcDate = moment(this.form.get('movingDate').value).toISOString();
    this.moveDTO.sites[0].deliveryPoints[0].dreDocumentStatus = this.form.get('dreStatus').value;
    return this.moveFormFacade.update(this.moveDTO).pipe(
      take(1),
      switchMap((moveDTO: MoveDTO) =>
        iif(() => this.remainTotalEnergiesClient() && !saveForLater, this.handleMoveIn(moveDTO), of(null))
      )
    );
  }

  private checkForMissingMandatoryIndexes(moveDTO: MoveDTO, date: string) {
    let isMovingDateTodayOrInPast = moment(date, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day'));
    let isThereMissingIndexes = moveDTO.sites[0]?.deliveryPoints.some((dp: DeliveryPoint) =>
      dp.meters.some((meters: MoveMeter) =>
        meters.registers.some((register: MoveRegister): boolean => register.value === null)
      )
    );
    return isMovingDateTodayOrInPast ? isThereMissingIndexes : false;
  }

  private getLocalForm() {
    let file = null;
    if (this.form.get('dreStatus').value === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL) {
      file = this.form.get('fileForPartialStatus')?.value;
    } else if (this.form.get('dreStatus').value === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL) {
      file = this.form.get('fileForFullStatus')?.value;
    }

    return {
      ...this.moveFormFacade?.state$?.value?.form,
      movingInfo: {
        ...this.form.value,
        fileName: file instanceof File ? file?.name : null,
      },
    };
  }

  private setServiceDateRange(
    dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi
  ): Observable<{ min: NgbDateStruct; max: NgbDateStruct }> {
    const moveDTO: MoveDTO = this.moveFormFacade.state$.value.moveDTO;
    const deliveryPointsCode: string[] = moveDTO.sites[0].deliveryPoints.map((dp: DeliveryPoint) => dp?.code);
    const preSwitchLights: PreSwitchLight[] = this.moveFormFacade.state$.value?.preSwitchLight;

    if (preSwitchLights && preSwitchLights.length > 0) {
      return of(
        this.calculateServiceDateRange(
          this.moveFormFacade.state$.value.preSwitchLight,
          deliveryPointsCode,
          dreCompletionStatus
        )
      );
    } else {
      return this.moveFormFacade.getPreSwitchLight().pipe(
        map((preSwitchLights: PreSwitchLight[]): { min: NgbDateStruct; max: NgbDateStruct } => {
          if (!preSwitchLights || !preSwitchLights.length) {
            return null;
          }
          this.moveFormFacade.updateData({ preSwitchLight: preSwitchLights });
          return this.calculateServiceDateRange(preSwitchLights, deliveryPointsCode, dreCompletionStatus);
        })
      );
    }
  }

  private calculateServiceDateRange(
    preSwitchLights: PreSwitchLight[],
    deliveryPointsCode: string[],
    dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi
  ): { min: NgbDateStruct; max: NgbDateStruct } {
    const minDates: string[] = this.getSwitchDates(preSwitchLights, deliveryPointsCode, dreCompletionStatus, 'minDate');
    const maxDates: string[] = this.getSwitchDates(preSwitchLights, deliveryPointsCode, dreCompletionStatus, 'maxDate');

    if (!minDates || !maxDates) {
      return null;
    }

    const min: moment.Moment = this.findClosestDate(minDates);
    const max: moment.Moment = this.findClosestDate(maxDates);

    this.form.get('movingDate').setValidators(dateRangeValidator(min, max));
    this.form.get('movingDate').updateValueAndValidity();

    return {
      min: { year: min.year(), month: min.month() + 1, day: min.date() },
      max: { year: max.year(), month: max.month() + 1, day: max.date() },
    };
  }

  private getSwitchDates(
    preSwitchLights: PreSwitchLight[],
    deliveryPointsCode: string[],
    dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
    propertyName: string
  ): string[] {
    return preSwitchLights
      .filter((pswl: PreSwitchLight) => deliveryPointsCode.includes(pswl?.ean))
      .map(
        (pswl: PreSwitchLight): string =>
          pswl.switchPossibilities.find(
            (switchPossibility: SwitchPossibility): boolean =>
              switchPossibility.switchType === this.mapDreCompletionStatus(dreCompletionStatus)
          )[propertyName]
      );
  }

  private mapDreCompletionStatus(dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi): SwitchType {
    return dreCompletionStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE
      ? SwitchType.ILCWithoutHandoverDocument
      : SwitchType.ILCWithHandoverDocument;
  }

  private findClosestDate(dates: string[]): moment.Moment {
    if (dates.length === 0) {
      return null;
    }

    const dateObjects = dates.map((dateStr) => moment(dateStr, 'YYYY-MM-DD')).filter((date) => date.isValid());

    if (dateObjects.length === 0) {
      return null;
    }

    const today = moment();
    let closestDate = dateObjects[0];
    let minDiff = Math.abs(today.diff(closestDate));

    dateObjects.forEach((date) => {
      const diff = Math.abs(today.diff(date));
      if (diff < minDiff) {
        minDiff = diff;
        closestDate = date;
      }
    });

    return closestDate;
  }
}

_('pages.move.movingInfo.alert.missingIndexes');
