<section class="mb-12">
  <div class="head flex items-end mb-4">
    <h3 class="text-xl">{{ 'pages.move.finalize.summaryNewMeters.title' | translate }}</h3>
    <span class="text-primary font-bold cursor-pointer ml-auto text-sm" (click)="edit()">
      {{ 'pages.move.finalize.editButton' | translate }}
    </span>
  </div>
  <div class="flex-row bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div class="flex-1 border-b border-grey/35 pb-8 mb-8">
      <h4 class="text-sm font-medium">
        {{ 'pages.move.finalize.summaryNewMeters.requestedServiceStartDate' | translate }}
      </h4>
      <span class="text-sm">
        {{
          elecDeliveryPoint?.requestedServiceDate || gasDeliveryPoint?.requestedServiceDate
            | date : 'dd/MM/yyyy' : undefined : facade.translate.currentLang + '-BE'
        }}
      </span>
    </div>
    <div
      *ngIf="elecDeliveryPoint"
      class="electricity {{ gasDeliveryPoint ? 'border-b border-grey/35 pb-8 mb-8' : '' }}"
    >
      <h3 class="text-base font-medium mb-4">{{ 'pages.move.finalize.summaryNewMeters.elecMeter' | translate }}</h3>
      <div class="sm:flex">
        <div class="flex-1">
          <span *ngIf="elecDeliveryPoint?.code" class="inline-block w-full text-sm"
            >{{ 'general.eanCode' | translate }} {{ elecDeliveryPoint?.code }}</span
          >
          <span *ngIf="elecMeter?.number" class="inline-block w-full text-sm"
            >{{ 'pages.move.finalize.summaryNewMeters.meterNumber' | translate }}: {{ elecMeter?.number }}</span
          >
          <span class="inline-block w-full text-sm"
            >{{ 'pages.move.meterType' | translate }}: {{ elecMeter?.type }}</span
          >

          <div>
            <h4 class="text-sm font-medium mt-4 mb-2">
              {{ 'pages.move.finalize.summaryNewMeters.exclusiveNightMeter' | translate }}
            </h4>
            <span class="inline-block w-full text-sm">{{ 'pages.move.finalize.yes' | translate }}</span>
          </div>

          <div *ngIf="site?.hasSolarPanels">
            <h4 class="text-sm font-medium mt-4 mb-2">
              {{ 'pages.move.finalize.summaryNewMeters.solarPanels' | translate }}
            </h4>
            <span *ngIf="site?.solarPanelKVA" class="inline-block w-full text-sm">
              {{ 'pages.move.finalize.summaryNewMeters.installedPower' | translate }}: {{ site?.solarPanelKVA }} KVA
            </span>
            <span *ngIf="site?.peakSolarPower" class="inline-block w-full text-sm">
              {{ 'pages.move.finalize.summaryNewMeters.peakPower' | translate }}: {{ site?.peakSolarPower }}
            </span>
          </div>
        </div>
        <div *ngIf="hasElecIndexes" class="flex-1 mt-4 sm:mt-0">
          <h4 class="text-sm font-medium">{{ 'pages.move.finalize.index' | translate }}</h4>
          <div class="mt-0">
            <ng-container *ngFor="let reading of elecMeter?.registers">
              <span *ngIf="reading?.value" class="inline-block w-full text-sm">
                {{ 'pages.move.finalize.summaryNewMeters.' + reading?.timeFrame + '_' + reading.type | translate }}:
                {{ reading.value }} kWh
              </span>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="gasDeliveryPoint" class="gas">
      <h3 class="text-base font-medium mb-4">{{ 'pages.move.finalize.summaryNewMeters.gasMeter' | translate }}</h3>
      <div class="sm:flex">
        <div class="flex-1">
          <span *ngIf="gasDeliveryPoint?.code" class="inline-block w-full text-sm"
            >{{ 'general.eanCode' | translate }}: {{ gasDeliveryPoint?.code }}</span
          >
          <span *ngIf="gasMeter?.number" class="inline-block w-full text-sm"
            >{{ 'pages.move.finalize.summaryNewMeters.meterNumber' | translate }}: {{ gasMeter?.number }}</span
          >
          <span class="inline-block w-full text-sm"
            >{{ 'pages.move.meterType' | translate }}: {{ gasMeter?.type }}</span
          >
        </div>
      </div>
    </div>
  </div>
</section>
