import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, combineLatest, filter } from 'rxjs';
import { Site } from '../../../user/models/site.interface';
import { takeUntil } from 'rxjs';
import { Alert } from '@app/shared/components/alert/alert.interface';
import { ServiceEligibility } from '../../../contracts/models/service-eligibility.interface';
import { ContactDetails } from '../../../contact/models/contactDetails.interface';
import { UserInfo } from '../../../user/models/userInfo.interface';
import { AccessRight, AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { EstimatedIndex } from '@app/modules/customer-zone/consumption/models/estimated-index.interface';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { ContractDtoCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { flandersZipCodes } from '@app/shared/components/banners/warning-banner/flanders-zip-codes';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public viewModel: DashboardViewModel;
  public estimatedIndexAlert: Alert;
  public accessRight = AccessRight;
  private notifier: Subject<void> = new Subject();
  private firstLoad = true;
  public giveawayBannerVisibility = false;
  public warningBannerVisibility = false;

  constructor(private facade: MainFacade) {}

  ngOnInit() {
    this.facade.utils.setPageTitle('pages.dashboard.pageTitle');
    this.facade.translate.onLangChange.pipe(takeUntil(this.notifier)).subscribe(() => {
      this.analytics();
    });

    this.handleFirstLoad();
    this.loadViewModel();
    this.analytics();
  }

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  public localStorageItem(id: string): string {
    return window.localStorage.getItem(id);
  }

  public switchSite(siteId: string): void {
    this.facade.updateActiveSite(siteId);
  }

  private setGiveawayBannerVisibility(): void {
    // TODO: use day.js after merge e-move
    const currentDate = new Date().getTime();
    const startDate = new Date('2024-12-12T09:00:00').getTime();
    const endDate = new Date('2024-12-27T23:59:59').getTime();

    this.giveawayBannerVisibility = currentDate > startDate && currentDate < endDate;
  }

  private setWarningBannerVisibility(): void {
    // TODO: use day.js after merge e-move
    const currentDate = new Date().getTime();
    const startDate = new Date('2025-01-16T00:00:01').getTime();
    const endDate = new Date('2025-02-28T23:59:59').getTime();

    this.viewModel?.contact$.subscribe((detail) => {
      this.warningBannerVisibility =
        flandersZipCodes.includes(detail.address.zipCode) && currentDate > startDate && currentDate < endDate;
    });
  }

  private handleFirstLoad(): void {
    if (this.localStorageItem('binding') && this.firstLoad) {
      this.facade.utils.redirectToSameRoute();
      this.firstLoad = false;
    }
  }

  private loadViewModel(): void {
    combineLatest({ accessRights: this.facade.accessRights$, sites: this.facade.sites$ })
      .pipe(
        filter((value) => value.accessRights !== undefined && value.sites !== undefined),
        takeUntil(this.notifier)
      )
      .subscribe(() => {
        this.viewModel = this.facade.getDashboardViewModel();
        this.estimatedIndexAlert = this.createEstimationAlert();
        this.setGiveawayBannerVisibility();
        this.setWarningBannerVisibility();
      });

    this.facade.translate.onLangChange
      .pipe(takeUntil(this.notifier))
      .subscribe(() => (this.estimatedIndexAlert = this.createEstimationAlert()));
  }

  private createEstimationAlert(): Alert {
    return {
      type: AlertType.successBackground,
      content: {
        title: this.facade.translate.instant('components.estimatedIndexDetail.alert.encode.title'),
        message: this.facade.translate.instant('components.estimatedIndexDetail.alert.encode.content'),
        linkLabel: this.facade.translate.instant('components.estimatedIndexDetail.alert.encode.link'),
        link: `/consumptions/estimated-index`,
      },
    };
  }

  private analytics(): void {
    this.facade.analytics.push(
      {
        event: 'pageview',
        page: {
          phase: 'care',
          category: 'cuzo',
          subCategory: 'homepage - cuzo',
        },
      },
      {
        reference: this.facade?.state$?.value?.reference,
        site: this.facade?.state$?.value?.activeSite,
        accessRights: this.facade?.state$?.value?.accessRights,
      }
    );
  }
}

export interface DashboardViewModel {
  reference: string;
  accessRights: AccessRights;
  computedData$: Observable<any>;
  sites$: Observable<Site[]>;
  activeSite$: Observable<Site>;
  contracts$: Observable<ContractDtoCuzoApi[]>;
  services$: Observable<ServiceEligibility[]>;
  contact$: Observable<ContactDetails>;
  userInfo$: Observable<UserInfo>;
  estimatedIndex$: Observable<EstimatedIndex[]>;
}

_('pages.dashboard.paymentBalance.toPay.payments');
_('pages.dashboard.paymentBalance.toPay.totalInvoicesToPay');
_('pages.dashboard.paymentBalance.toPay.totalToPay');
_('pages.dashboard.paymentBalance.toPay.currentInvoices');
_('pages.dashboard.paymentBalance.toRefund.currentInvoices');
_('pages.dashboard.paymentBalance.toRefund.totalToRefund');
_('pages.dashboard.paymentBalance.toRefund.totalAmountOfInvoiceToRefund');
_('pages.dashboard.paymentBalance.toRefund.refund');
