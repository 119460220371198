<app-leaving-banner [moveState]="moveFormFacade.state$ | async"></app-leaving-banner>
<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto" [class.readonly]="(moveFormFacade.state$ | async)?.readonly">
  <div class="mb-16">
    <div class="head mb-12 md:mb-24">
      <h2 class="mt-12 mb-8 font-display font-normal text-4xl te-text-gradient-home inline-block md:my-12">
        {{ 'components.move.form.title' | translate }}
      </h2>
      <div class="flex flex-col bg-white md:bg-transparent rounded-4xl box-shadow-small md:shadow-none">
        <div class="flex justify-between md:block m-8 md:m-4">
          <app-move-progress></app-move-progress>
          <ng-container *ngIf="moveFormFacade.isMobileScreen$ | async">
            <img
              *ngIf="!showSumUpBox"
              ngSrc="./assets/img/icons/standalone/red/actions.svg"
              width="50"
              height="50"
              class="flex rounded-full w-12 h-12 border p-3 border-primary cursor-pointer"
              alt="show more"
              (click)="showSumUpBox = !showSumUpBox"
            />
            <img
              *ngIf="showSumUpBox"
              ngSrc="./assets/img/icons/standalone/red/arrow-right.svg"
              width="50"
              height="50"
              class="flex rounded-full w-12 h-12 border p-3 border-primary -rotate-90 cursor-pointer"
              alt="show less"
              (click)="showSumUpBox = !showSumUpBox"
            />
          </ng-container>
        </div>
        <ng-container *ngIf="moveFormFacade.isMobileScreen$ | async">
          <div [ngClass]="showSumUpBox ? 'expanded' : 'collapsed'">
            <div class="mx-8 border-t border-t-grey md:border-none"></div>
            <app-summary-aside [moveState]="moveFormFacade.state$ | async"> </app-summary-aside>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-col">
      <div class="md:grid md:grid-cols-12 gap-16">
        <div class="md:col-span-8">
          <ng-container *ngIf="moveFormFacade.loader$ | async as load">
            <div
              class="wrapper card border-none shadow-none md:box-shadow-small mb-8"
              [class.loaded]="load === LoaderStatus.LOADED"
            >
              <div class="{{ load === LoaderStatus.LOADING ? 'fade-in' : 'fade-out' }}">
                <div class="flex absolute top-[12rem] w-full justify-center">
                  <span class="border-gray-300 h-12 w-12 animate-spin rounded-full border-2 border-t-primary"> </span>
                </div>
              </div>
              <div class="{{ load === LoaderStatus.LOADED ? 'fade-in' : 'fade-out' }}">
                <app-alert
                  *ngIf="showResponseErrorAlert"
                  class="inline-block mb-12 w-full"
                  [flatDesign]="true"
                  [alert]="{
                    type: AlertType.error,
                    content: {
                      titleKey: 'components.move.form.error.responseError.title',
                      messageKey: 'components.move.form.error.responseError.body'
                    }
                  }"
                ></app-alert>
                <router-outlet></router-outlet>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="mt-16 md:mt-0 md:col-span-4">
          <ng-container *ngIf="!(moveFormFacade.isMobileScreen$ | async)">
            <app-summary-aside [moveState]="moveFormFacade.state$ | async"> </app-summary-aside>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
