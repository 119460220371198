import { AbstractControl, FormArray, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

export type FormGroupControls<T> = {
  [K in keyof T]: T[K] extends Array<infer U>
    ? FormArray<FormGroup<FormGroupControls<U>>>
    : T[K] extends object
    ? FormGroup<FormGroupControls<T[K]>>
    : FormControl<T[K]>;
};

export const addValidator = (control: FormControl | AbstractControl, validators: ValidatorFn[]): void => {
  control.addValidators(validators);
  control.updateValueAndValidity({ emitEvent: false });
};

export const removeValidator = (control: FormControl | AbstractControl, validators: ValidatorFn[]): void => {
  control.removeValidators(validators);
  control.updateValueAndValidity({ emitEvent: false });
};

export const deepCopy = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));
