export const flandersZipCodes = [
  '1540',
  '1541',
  '1570',
  '1755',
  '2000',
  '2018',
  '2020',
  '2030',
  '2040',
  '2050',
  '2060',
  '2070',
  '2100',
  '2140',
  '2150',
  '2170',
  '2180',
  '2180',
  '2600',
  '2610',
  '2660',
  '3500',
  '3501',
  '3510',
  '3511',
  '3512',
  '3700',
  '3720',
  '3721',
  '3722',
  '3723',
  '3724',
  '3730',
  '3732',
  '3740',
  '3742',
  '3746',
  '3840',
  '3945',
  '3980',
  '8700',
  '8750',
  '8755',
  '8760',
  '9080',
  '9090',
  '9120',
  '9130',
  '9150',
  '9160',
  '9180',
  '9185',
  '9810',
  '9820',
  '9840',
];
