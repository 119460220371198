import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import moment from 'moment';
import { dateRangeValidator } from '@app/shared/date-picker-field/validators';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeliveryPoint } from '@app/modules/customer-zone/move/models/movein.interface';

@Component({
  selector: 'app-energy-type',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, DatePickerFieldModule, TranslateModule],
  templateUrl: './energy-type.component.html',
  styleUrls: ['./energy-type.component.scss'],
})
export class EnergyTypeComponent implements OnInit {
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  @Input() deliveryPoints: DeliveryPoint[];
  serviceDateRange: { min: NgbDateStruct; max: NgbDateStruct } = { min: null, max: null };

  constructor(private controlContainer: ControlContainer) {}

  get energyTypeFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {
    this.serviceDateRange = this.setDateRange();
    this.emitFormValidity();
  }

  onDateSelection(date: string): void {
    this.energyTypeFormGroup.get('date').setValue(date);
  }

  private setDateRange(): { min: NgbDateStruct; max: NgbDateStruct } {
    const today: moment.Moment = moment();

    const minDate: moment.Moment = today.clone().subtract(30, 'days');
    const maxDate: moment.Moment = today.clone().add(30, 'days');

    this.setValidators(minDate, maxDate);

    const formatDate = (date: moment.Moment) => {
      return {
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
      };
    };

    return {
      min: formatDate(minDate),
      max: formatDate(maxDate),
    };
  }

  private setValidators(minDate: moment.Moment, maxDate: moment.Moment) {
    this.energyTypeFormGroup.get('date').setValidators([dateRangeValidator(minDate, maxDate)]);
    this.energyTypeFormGroup.updateValueAndValidity();
  }

  private emitFormValidity() {
    this.formValidityEmitter.emit(this.energyTypeFormGroup.valid);
    this.energyTypeFormGroup.valueChanges.subscribe(() =>
      this.formValidityEmitter.emit(this.energyTypeFormGroup.valid)
    );
  }
}

_('pages.move.energyType.energy.electricity');
_('pages.move.energyType.energy.gas');
