<div class="px-8 xl:max-w-screen-xl xl:w-full xl:m-auto estimated-index">
  <div class="mb-16">
    <div class="estimated-index__header">
      <h1 class="estimated-index__title mb-16 mt-16">
        {{ 'pages.estimatedIndex.title' | translate }}
      </h1>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-12 gap-16">
      <ng-container *ngIf="indexes$ | async as indexes">
        <div class="md:col-span-8">
          <app-no-estimated-index *ngIf="indexes.length === 0"></app-no-estimated-index>
          <app-estimated-indexes [indexes]="indexes" *ngIf="indexes.length > 0"></app-estimated-indexes>
        </div>
      </ng-container>
    </div>
  </div>
</div>
