import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { AlertComponent } from './components/alert/alert.component';
import { ModalComponent } from './components/modal/modal.component';
import { ImageIconComponent } from './components/image-icon/image-icon.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { ErrorComponent } from './pages/error/error.component';
import { SitesSwitcherComponent } from './components/sites-switcher/sites-switcher.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChatCtaComponent } from './components/chat-cta/chat-cta.component';
import { StripHtmlTagsPipe } from './pipes/strip-html-tags/strip-html-tags.pipe';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { ConsentCheckboxComponent } from './components/consent-checkbox/consent-checkbox.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMeterIndexComponent } from './components/input-meter-index/input-meter-index.component';
import { InputDateComponent } from './components/input-date/input-date.component';
import { RouterModule } from '@angular/router';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { SlideToggleDirective } from './directives/slide-toggle.directive';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { EncodeIndexCtaComponent } from './components/encode-index-cta/encode-index-cta.component';
import { StepProgressBarComponent } from './components/step-progress-bar/step-progress-bar.component';
import { AnalyticsModule } from './modules/analytics/analytics.module';
import { InputInstallmentSliderComponent } from './components/input-installment-slider/input-installment-slider.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { Nl2brPipe } from './pipes/nl2br/nl2br.pipe';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { FirstLetterPipe } from './pipes/first-letter/first-letter.pipe';
import { BreakingNewsComponent } from './components/breaking-news/breaking-news.component';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import { RouteTransformerDirective } from './directives/route-transformer.directive';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { DatePickerComponent } from '@app/modules/customer-zone/tevc-monitoring/components/date-picker/date-picker.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CarouselItemDirective } from './components/carousel/carousel-item.directive';
import { DatePickerGraphComponent } from '../shared/components/date-picker-graph/date-picker-graph.component';
import { SwitcherComponent } from './components/switcher/switcher.component';
import { IMaskModule } from 'angular-imask';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { SiteAddressComponent } from './components/site-address/site-address.component';
import { ContactAddressComponent } from './components/contact-address/contact-address.component';
import { AccessControlDirective } from './directives/access-control.directive';
import { MultipleAccessControlDirective } from './directives/multiple-access-control.directive';
import { SelfBillingComponent } from './pages/self-billing/self-billing.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { ProductSwapComponent } from './components/product-swap/product-swap.component';
import { ContactComponent } from './components/product-swap/contact/contact.component';
import { ToggleSwitchComponent } from './components/toggle-switch/toggle-switch.component';
import { WarningTooltipComponent } from './components/warning-tooltip/warning-tooltip.component';
import { EliqTabComponent } from './components/eliq-tab/eliq-tab.component';
import { UnderMaintenanceComponent } from './components/under-maintenance/under-maintenance.component';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { EliqSyncInProgressComponent } from './components/eliq-sync-in-progress/eliq-sync-in-progress.component';
import { TotalenergiesUiLibraryModule } from '@totalenergiescode/springbox-totalenergies-ui-library';

@NgModule({
  declarations: [
    WarningTooltipComponent,
    ErrorComponent,
    ChatCtaComponent,
    CustomSelectComponent,
    ConsentCheckboxComponent,
    InputMeterIndexComponent,
    InputDateComponent,
    CookiePolicyComponent,
    AddressFormComponent,
    StepProgressBarComponent,
    PageNotFoundComponent,
    Nl2brPipe,
    ProgressCircleComponent,
    FirstLetterPipe,
    EncodeIndexCtaComponent,
    InputInstallmentSliderComponent,
    BreakingNewsComponent,
    LangSwitcherComponent,
    RouteTransformerDirective,
    UnauthorizedComponent,
    DatePickerComponent,
    CarouselComponent,
    CarouselItemDirective,
    SwitcherComponent,
    DatePickerGraphComponent,
    SiteAddressComponent,
    ContactAddressComponent,
    SelfBillingComponent,
    MultipleAccessControlDirective,
    InputNumberComponent,
    ProgressBarComponent,
    OnlyNumberDirective,
    ProductSwapComponent,
    ContactComponent,
    ToggleSwitchComponent,
    EliqTabComponent,
    UnderMaintenanceComponent,
    TruncatePipe,
    EliqSyncInProgressComponent,
  ],
  imports: [
    CommonModule,
    ImageIconComponent,
    TranslateModule,
    NgbModule,
    ModalComponent,
    ReactiveFormsModule,
    RouterModule,
    AnalyticsModule,
    IMaskModule,
    NgOptimizedImage,
    SitesSwitcherComponent,
    AccessControlDirective,
    TooltipComponent,
    AlertComponent,
    StripHtmlTagsPipe,
    SlideToggleDirective,
    TotalenergiesUiLibraryModule,
  ],
  exports: [
    AlertComponent,
    ModalComponent,
    ImageIconComponent,
    TooltipComponent,
    WarningTooltipComponent,
    SitesSwitcherComponent,
    ChatCtaComponent,
    StripHtmlTagsPipe,
    CustomSelectComponent,
    ConsentCheckboxComponent,
    InputMeterIndexComponent,
    InputDateComponent,
    CookiePolicyComponent,
    SlideToggleDirective,
    AccessControlDirective,
    MultipleAccessControlDirective,
    AddressFormComponent,
    EncodeIndexCtaComponent,
    StepProgressBarComponent,
    InputInstallmentSliderComponent,
    Nl2brPipe,
    ProgressCircleComponent,
    FirstLetterPipe,
    BreakingNewsComponent,
    LangSwitcherComponent,
    DatePickerComponent,
    CarouselComponent,
    CarouselItemDirective,
    SwitcherComponent,
    DatePickerGraphComponent,
    SiteAddressComponent,
    ContactAddressComponent,
    InputNumberComponent,
    ProgressBarComponent,
    OnlyNumberDirective,
    ProductSwapComponent,
    ToggleSwitchComponent,
    EliqTabComponent,
    TruncatePipe,
    EliqSyncInProgressComponent,
    TotalenergiesUiLibraryModule,
  ],
})
export class SharedModule {}

_('general.noAccess.reasons.one.title');
_('general.noAccess.reasons.one.description');
_('general.noAccess.reasons.two.title');
_('general.noAccess.reasons.two.description');
_('general.noAccess.reasons.three.title');
_('general.noAccess.reasons.three.description');
_('general.noAccess.reasons.four.title');
_('general.noAccess.reasons.four.description');
_('general.noAccess.reasons.five.title');
_('general.noAccess.reasons.five.description');
_('general.noAccess.reasons.six.title');
_('general.noAccess.reasons.six.description');
_('general.noAccess.reasons.seven.title');
_('general.noAccess.reasons.seven.description');
_('general.noAccess.reasons.noAccessDueToB2BWithoutPixel.title');
_('general.noAccess.reasons.noAccessDueToB2BWithoutPixel.description');
_('general.noAccess.reasons.noAccessDueToNoContractFound.title');
_('general.noAccess.reasons.noAccessDueToNoContractFound.description');

_('dynamicContent.b2c.chatSchedule.label');
_('dynamicContent.b2c.faqLink.label');
_('dynamicContent.b2c.contactLink.label');
_('dynamicContent.b2c.footer.item1.label');
_('dynamicContent.b2c.footer.item1.link');
_('dynamicContent.b2c.footer.item2.label');
_('dynamicContent.b2c.footer.item2.link');
_('dynamicContent.b2c.footer.item3.label');
_('dynamicContent.b2c.footer.item3.link');
_('dynamicContent.b2c.quickLinks.item1.label');
_('dynamicContent.b2c.quickLinks.item1.link');
_('dynamicContent.b2c.quickLinks.item2.label');
_('dynamicContent.b2c.quickLinks.item2.link');
_('dynamicContent.b2c.quickLinks.item3.label');
_('dynamicContent.b2c.quickLinks.item3.link');
_('dynamicContent.b2c.quickLinks.item4.label');
_('dynamicContent.b2c.quickLinks.item4.link');

_('dynamicContent.b2c.conditionalQuickLinks.item1.label');
_('dynamicContent.b2c.conditionalQuickLinks.item1.link');

_('dynamicContent.b2c.infoBox.item1.title');
_('dynamicContent.b2c.infoBox.item1.content');

_('dynamicContent.b2b.chatSchedule.label');
_('dynamicContent.b2b.faqLink.label');
_('dynamicContent.b2b.contactLink.label');
_('dynamicContent.b2b.footer.item1.label');
_('dynamicContent.b2b.footer.item1.link');
_('dynamicContent.b2b.footer.item2.label');
_('dynamicContent.b2b.footer.item2.link');
_('dynamicContent.b2b.footer.item3.label');
_('dynamicContent.b2b.footer.item3.link');
_('dynamicContent.b2b.quickLinks.item1.label');
_('dynamicContent.b2b.quickLinks.item1.link');
_('dynamicContent.b2b.quickLinks.item2.label');
_('dynamicContent.b2b.quickLinks.item2.link');
_('dynamicContent.b2b.quickLinks.item3.label');
_('dynamicContent.b2b.quickLinks.item3.link');
_('dynamicContent.b2b.quickLinks.item4.label');
_('dynamicContent.b2b.quickLinks.item4.link');

_('dynamicContent.b2b.infoBox.item1.title');
_('dynamicContent.b2b.infoBox.item1.content');
