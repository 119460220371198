<div *ngIf="visibility" class="bg-orange-lighter w-full">
  <div class="px-8 py-4 md:flex md:justify-between md:items-center text-sm xl:max-w-screen-xl xl:w-full xl:m-auto">
    <div class="flex items-center gap-x-8">
      <img ngSrc="./assets/img/icons/standalone/orange/banner-warning-circle.svg" height="40" width="40"
           alt="warning" />
      <div class="text-dark-grey">
        <div class="pb-2 font-bold text-orange md:text-dark-grey md:pb-0">
          <span *ngIf="title" class="pl-4 text-base leading-9 md:pl-0 md:leading-5 md:text-sm">
            {{ title }}
          </span>
        </div>
        <div *ngIf="body" [innerHTML]="body"></div>
      </div>
    </div>
    <div *ngIf="url" class="flex items-center gap-x-10 justify-between md:justify-start">
      <a href="{{ url }}" target="_blank" class="text-primary font-bold">
        {{ 'components.widgets.disaggreagtionChart.moreInfo' | translate }}
      </a>
    </div>
  </div>
</div>
