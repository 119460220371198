import { Component, ElementRef, enableProdMode, NgZone, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent } from '@ngx-translate/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  Event as RouterEvent,
} from '@angular/router';
import { LoaderService } from './shared/modules/loader/services/loader.service';
import { LanguageService } from './core/language/services/language.service';
import { LowerCasePipe } from '@angular/common';
import { AppConfig } from './shared/utils/app-config';
import { AccessRights } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { Observable, skip, take } from 'rxjs';
import { MainFacade } from '@app/core/facade/main.facade';
import { DeviceService } from '@app/core/service/device.service';
import { CustomerType } from '@totalenergiescode/springbox-salesforce-chat';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('app') app: ElementRef;
  @ViewChild('content') content;
  public accessRights$: Observable<AccessRights>;
  public language: string;
  public loading;
  public isMobileApp = false;
  protected readonly customerType = CustomerType;
  private latestTrigger: string;

  private browserGlobals = {
    windowRef: () => window,
    documentRef: () => document,
  };

  constructor(
    private facade: MainFacade,
    private router: Router,
    private loaderService: LoaderService,
    private languageService: LanguageService,
    private lowerCasePipe: LowerCasePipe,
    ngZone: NgZone,
    private conf: AppConfig,
    public deviceService: DeviceService
  ) {
    if (this.conf.config.production) {
      try {
        enableProdMode();
      } catch (exception) {
        console.warn(
          'BUGFIX: calling isDevMode() in imports before enableProdMode() throws exception' +
            'https://github.com/angular/angular-cli/issues/8340#\n',
          exception
        );
      }
    }

    this.language = this.languageService.init();
    this.loaderService.getLoaderStatus().subscribe((loading) => (this.loading = loading));
    this.router.events.subscribe((event: RouterEvent) => this.navigationInterceptor(event));

    ngZone.onStable.subscribe((_) => this.mergeQueryParamToAllLinks('client_id'));
    this.facade.translate.onLangChange.subscribe((event: LangChangeEvent) => (this.language = event.lang));
    const internalPath = window.location.pathname.replace(
      this.conf.config.path.prefix[this.lowerCasePipe.transform(this.facade.translate.currentLang)],
      ''
    );
    this.router.navigateByUrl(internalPath + window.location.search, { skipLocationChange: true });
    this.accessRights$ = this.facade.accessRights$;

    this.deviceService.type$.pipe(skip(1), take(1)).subscribe(({ isMobileApp }) => {
      this.isMobileApp = isMobileApp;
      this.facade.analytics.init(isMobileApp);
    });
  }

  ngOnInit() {
    const window = this.browserGlobals.windowRef();

    // Todo also load facebook sdk in different language when switching.
    const scriptId = 'facebookSDK';
    const langCode = this.facade.translate.currentLang === 'fr' ? 'fr_FR' : 'nl_BE';
    const document = this.browserGlobals.documentRef();

    const fbScript = document.createElement('script');
    fbScript.id = scriptId;
    fbScript.async = true;
    fbScript.defer = true;
    fbScript.crossOrigin = 'anonymous';
    fbScript.src = `https://connect.facebook.net/${langCode}/sdk.js`;
    document.head.insertBefore(fbScript, document.head.firstChild);

    if (!window['fbAsyncInit']) {
      window['fbAsyncInit'] = () => {
        window['FB'].init({
          appId: this.conf.config.facebookAppId,
          autoLogAppEvents: true,
          cookie: true,
          xfbml: true,
          version: 'v8.0',
        });
      };
    }
  }

  freezeScrolling(freezeScroll: boolean) {
    if (freezeScroll) {
      this.app.nativeElement.style.top = `-${window.scrollY}px`;
      this.app.nativeElement.style.position = 'fixed';
      document.body.className = 'menu-open';
    } else {
      const scrollY = this.app.nativeElement.style.top;
      this.app.nativeElement.style.position = '';
      this.app.nativeElement.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      document.body.className = 'menu-closed';
    }
  }

  private mergeQueryParamToAllLinks(queryParam: string) {
    const params = new URL(document.location.toString()).searchParams;
    const clientId = params.get(queryParam);
    if (!clientId) {
      return;
    }
    const links = document.querySelectorAll('a');

    links.forEach((link) => {
      if (!link.href || link.href.indexOf('totalenergies') === -1) {
        return;
      }
      const url = new URL(link.href);
      if (url.searchParams.has(queryParam)) {
        return;
      }
      url.searchParams.set(queryParam, clientId);
      link.setAttribute('href', url.toString());
    });
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      if (this.isMobileApp && !event.url.includes('webview')) {
        this.router.navigateByUrl(`/webview${event.url}`);
        return;
      }

      if (this.showLoadingOnPageChange(event.url)) {
        this.loaderService.show();
      }
      // We need to take action if we react to a browser back button.
      // The pushed state is a prefixed path the angular router can't resolve
      if (event.navigationTrigger === 'popstate') {
        const internalPath = event.url.replace(
          this.conf.config.path.prefix[this.lowerCasePipe.transform(this.facade.translate.currentLang)],
          ''
        );
        // Remember that we go back in history. Before we trigger an internal navigation.
        this.latestTrigger = event.navigationTrigger;
        this.router.navigateByUrl(internalPath, { skipLocationChange: true });
      }
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      setTimeout(() => this.loaderService.requestsCounter === 0 && this.loaderService.hide(), 700);
    }

    if (event instanceof NavigationEnd) {
      // Lates navigation was a pop state. No rewrite needed.
      if (this.latestTrigger === 'popstate') {
        this.latestTrigger = null;
        return;
      }
      // Rewrite the url with a prefix if needed.
      let url = this.conf.config.path.prefix[this.lowerCasePipe.transform(this.facade.translate.currentLang)];
      url += event.urlAfterRedirects === '/' ? '' : event.urlAfterRedirects;
      if (this.isMobileApp && !url.includes('be-b2c-customer-zone_mobile-app')) {
        url = this.updateQueryParam(url, 'client_id', 'be-b2c-customer-zone_mobile-app');
      }
      window.history.pushState({}, null, url);
    }
  }

  private showLoadingOnPageChange(url: string): boolean {
    return !this.isMoveForm(url);
  }

  private isMoveForm(url: string): boolean {
    const regex: RegExp = /^\/preferences\/move\/form\/.+/;
    return regex.test(url);
  }

  private updateQueryParam(url: string, paramName: string, paramValue: string): string {
    const urlTree = this.router.parseUrl(url);
    const queryParams = { ...urlTree.queryParams };
    queryParams[paramName] = paramValue;
    urlTree.queryParams = queryParams;
    return urlTree.toString();
  }
}
