<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.finalize.summary' | translate }}
    </h2>
    <p class="mb-8">
      {{ 'pages.move.finalize.verifyDetails' | translate }}
    </p>

    <app-summary-moving-info [moveDTO]="moveDTO" (editEmitter)="edit($event)"> </app-summary-moving-info>

    <app-summary-my-meters [moveDTO]="moveDTO" (editEmitter)="edit($event)"> </app-summary-my-meters>
    <!-- When user is not staying in TE (first question of emove flow, we are not creating amendment for new contract)
        Then we know from amendment field that my meters and new address is not showed -->
    <app-summary-new-address *ngIf="moveDTO?.amendmentField" [registration]="registration" (editEmitter)="edit($event)">
    </app-summary-new-address>

    <app-summary-new-meters *ngIf="moveDTO?.amendmentField" [registration]="registration" (editEmitter)="edit($event)">
    </app-summary-new-meters>

    <app-summary-new-occupant
      [knowBuyer]="moveDTO?.knowBuyer"
      [registration]="registrationNewOccupant"
      (editEmitter)="edit($event)"
    ></app-summary-new-occupant>
  </div>
</div>

<app-navigation [stepValidity]="true" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
</app-navigation>
