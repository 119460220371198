<div *ngFor="let index of indexes" class="card-primary mb-8">
  <h3 class="mb-6 text-2xl font-medium text-grey-dark">
    {{ 'general.product.' + index.energy | lowercase | translate }}
  </h3>
  <ng-container *ngIf="index">
    <div>
      <app-estimated-index-detail [estimatedIndex]="index"></app-estimated-index-detail>
    </div>
  </ng-container>
</div>
