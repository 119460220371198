import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  distinctUntilChanged,
  filter,
  forkJoin,
  iif,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap,
  take,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { MoveFormPaths, Paths } from '@app/core/models/paths';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Contact,
  INITIAL_MOVE_STATE,
  LinkType,
  Meter,
  MoveDTO,
  MoveSite,
  MoveState,
  MoveStatus,
} from '@app/core/state/move.state';
import { MoveFormService } from '@app/modules/customer-zone/move/services/form/move-form.service';
import { LoaderStatus, priorityOrder, ViewStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { ErrorHandlerService } from '@app/core/service/error-handler.service';
import { ApiResponse } from '@app/shared/models/api.inteface';
import { HttpParams } from '@angular/common/http';
import { ReferenceService } from '@app/modules/customer-zone/user/services/reference/reference.service';
import { ToastrService } from 'ngx-toastr';
import {
  MoveDataCuzoApi,
  MoveDataStatusEnumCuzoApi,
  SiteStatusCuzoApi,
  UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import { SiteService } from '@app/modules/customer-zone/user/services/site/site.service';
import { Site } from '@app/modules/customer-zone/user/models/site.interface';
import { Address } from '@app/shared/models/address.interface';
import { ContactService } from '@app/modules/customer-zone/contact/services/contact/contact.service';
import { ContactDetails } from '@app/modules/customer-zone/contact/models/contactDetails.interface';
import { MobileScreenService } from '@app/shared/services/mobile-screen.service';
import moment from 'moment';
import { BackendToFrontendMapperService } from '@app/modules/customer-zone/move/services/mapper/backend-to-frontend-mapper.service';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import {
  BillingMethod,
  DeliveryPoint,
  MoveInRegistration,
  PaymentMethod,
} from '@app/modules/customer-zone/move/models/movein.interface';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { SaveFormForLaterService } from '@app/modules/customer-zone/move/services/form/save-form-for-later.service';
import { UtilsService } from '@app/shared/utils/utils.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class MoveFormFacade {
  static readonly MOVE_URL: string = `/${Paths.preferences}/${Paths.move}`;
  static readonly FORM_URL: string = `${MoveFormFacade.MOVE_URL}/form`;
  readonly state$: BehaviorSubject<MoveState> = new BehaviorSubject(INITIAL_MOVE_STATE);
  readonly loader$: BehaviorSubject<LoaderStatus> = new BehaviorSubject(LoaderStatus.LOADED);
  readonly isMobileScreen$: Observable<boolean> = this.mobileScreenService.getIsMobile();
  readonly moveDTO$: Observable<MoveDTO> = this.state$.asObservable().pipe(
    map((state: MoveState) => state.moveDTO),
    distinctUntilChanged()
  );
  readonly route$: Observable<MoveFormPaths[]> = this.state$.asObservable().pipe(
    map((state: MoveState) => state.route),
    distinctUntilChanged()
  );
  readonly currentStep$: Observable<MoveFormPaths> = this.state$.asObservable().pipe(
    map((state: MoveState) => state.currentStepUrl),
    distinctUntilChanged()
  );

  constructor(
    private readonly moveFormService: MoveFormService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly errorHandlerService: ErrorHandlerService,
    private readonly referenceService: ReferenceService,
    private readonly siteService: SiteService,
    private readonly contactService: ContactService,
    private readonly toastrService: ToastrService,
    private readonly mobileScreenService: MobileScreenService,
    private readonly saveFormForLater: SaveFormForLaterService,
    private readonly utilsService: UtilsService,
    private readonly translateService: TranslateService
  ) {
    this.currentStep$
      .pipe(filter((): boolean => this.state$.value !== INITIAL_MOVE_STATE))
      .subscribe((currentStep: MoveFormPaths): void => {
        const url: string = `${MoveFormFacade.FORM_URL}/${currentStep}`;
        const moveId: string = this.state$?.value?.moveDTO?.id;
        const queryParams: Params = moveId ? { moveId } : {};
        this.router.navigate([url], {
          queryParamsHandling: 'merge',
          queryParams,
        });
      });

    const moveId: string = this.route?.snapshot?.queryParamMap?.get('moveId') || null;
    if (moveId) {
      this.resumeMoveFromPageReload(moveId);
    } else {
      this.redirectToMoveDashboard();
    }
  }

  get translate() {
    return this.translateService;
  }

  create(reference: string, siteId: string = null): Observable<MoveDTO> {
    if (!siteId) {
      siteId = this.getMoveSiteId();
    }
    const params: HttpParams = new HttpParams().set('loader', 'false');

    return forkJoin([
      this.siteService.getSite(reference, siteId, false),
      this.contactService.getContactDetails(reference, false),
    ]).pipe(
      take(1),
      switchMap(([site, contact]) => {
        const moveDTO: MoveDTO = this.state$.value.moveDTO;
        const moveSite: MoveSite = moveDTO?.sites[0];
        moveSite.address = this.getSiteAddress(site);
        moveDTO.contacts = [this.getContact(contact)];
        moveDTO.billingInfo = {}; // we need to initialise billingInfo, why ? because...
        return this.moveFormService.create(reference, siteId, moveDTO, params).pipe(
          take(1),
          this.getCatchErrorOperatorFunction(),
          tap((response: MoveDTO) => {
            this.resetResponseError();
            this.updateData({
              moveDTO: response,
              form: BackendToFrontendMapperService.map(response),
            });
          })
        );
      })
    );
  }

  update(moveDTO: MoveDTO = null): Observable<MoveDTO> {
    moveDTO = moveDTO || this.state$.value.moveDTO;
    const siteId: string = this.getMoveSiteId();
    const params: HttpParams = new HttpParams().set('loader', 'false');

    return this.moveFormService.update(moveDTO.customerReference, siteId, moveDTO?.id, moveDTO, params).pipe(
      take(1),
      this.getCatchErrorOperatorFunction(),
      tap((response: MoveDTO) => {
        this.resetResponseError();
        this.updateData({
          moveDTO: response,
          form: BackendToFrontendMapperService.map(response),
        });
      })
    );
  }

  archive(reference: string = null, siteId: string = null, moveOutId: string = null): Observable<MoveDTO> {
    reference = reference || this.state$?.value?.moveDTO?.customerReference;
    siteId = siteId || this.getMoveSiteId();
    moveOutId = moveOutId || this.state$?.value?.moveDTO?.id;
    return this.moveFormService.archiveMove(reference, siteId, moveOutId).pipe(
      take(1),
      catchError((): Observable<any> => {
        this.toastrService.error('An error happened when archiving move');
        return of(null);
      })
    );
  }

  createRegistration(reference: string, payload: MoveInRegistration): Observable<MoveInRegistration> {
    const params: HttpParams = new HttpParams().set('loader', 'false');

    return this.moveFormService.createRegistration(reference, payload, params).pipe(
      this.getCatchErrorOperatorFunction(),
      tap((occupantRegistration: MoveInRegistration) => {
        this.resetResponseError();
        const state: MoveState = this.state$.value;
        this.updateData({
          ...state,
          form: BackendToFrontendMapperService.map(state.moveDTO, state.registration, occupantRegistration),
        });
      })
    );
  }

  // Used for new occupant registration
  createAndLinkRegistration(regPayload: MoveInRegistration): Observable<MoveInRegistration> {
    const state: MoveState = this.state$.value;
    const moveDTO = state.moveDTO;
    const siteId: string = this.getMoveSiteId();

    return this.createRegistration(state.moveDTO.customerReference, regPayload).pipe(
      switchMap((registration: MoveInRegistration) => {
        const params: HttpParams = new HttpParams().set('loader', 'false').set('linkType', LinkType.NEW_BUYER);
        return forkJoin({
          linking: this.moveFormService.linkMoveInRegistration(
            state.moveDTO.customerReference,
            siteId,
            moveDTO,
            registration?.id,
            params
          ),
          registrationNewOccupant: of(registration),
        });
      }),
      this.getCatchErrorOperatorFunction(),
      switchMap((value: { registrationNewOccupant: MoveInRegistration; linking: MoveInRegistration }) => {
        this.resetResponseError();
        this.updateData({ registrationNewOccupant: value.registrationNewOccupant });
        this.resetResponseError();
        return of(value.linking);
      })
    );
  }

  saveNewOccupantRegistrationAndMove(hasNewOccupant: boolean, updatedReg: MoveInRegistration): Observable<MoveDTO> {
    const state: MoveState = this.state$.value;
    const moveDTO = state.moveDTO;
    moveDTO.knowBuyer = hasNewOccupant;

    let updateObservable = this.update(moveDTO).pipe(take(1));
    // If new occupant reg exists and user select that residence has new buyer
    // Now we only disable whole form if new occupant registration was created, so we don't do update
    // After deploy we should allow user to update contact inforamtions in form
    if (hasNewOccupant && state?.registrationNewOccupant) {
      updateObservable = this.updateRegistration(updatedReg, true).pipe(
        take(1),
        switchMap(() => this.update(moveDTO).pipe(take(1)))
      );
    } else if (hasNewOccupant) {
      const { processDetail, ...payload } = updatedReg;
      payload.status = 'INITIATED';
      updateObservable = this.createAndLinkRegistration(payload).pipe(
        switchMap(() => this.update(moveDTO).pipe(take(1)))
      );
    }

    return updateObservable;
  }

  get(): Observable<MoveDTO> {
    const state: MoveState = this.state$.value;
    const siteId: string = this.getMoveSiteId();
    const params: HttpParams = new HttpParams().set('loader', 'false');
    return this.moveFormService.get(state.moveDTO.customerReference, siteId, state.moveDTO.id, params).pipe(take(1));
  }

  getRegistration(
    moveOutId: string,
    reference: string = null,
    linkType = LinkType.MOVE_IN,
    loader = false
  ): Observable<MoveInRegistration> {
    const state: MoveState = this.state$.value;
    reference = reference || state.moveDTO.customerReference;
    const params: HttpParams = new HttpParams().set('loader', loader).set('linkType', linkType);
    return this.moveFormService
      .getRegistration(reference, moveOutId, params)
      .pipe(map((registrations: MoveInRegistration[]) => (registrations?.length ? registrations[0] : null)));
  }

  updateRegistration(registration: MoveInRegistration, newOccupantReg = false): Observable<MoveInRegistration> {
    const state: MoveState = this.state$.value;
    const params: HttpParams = new HttpParams().set('loader', 'false');

    return this.moveFormService
      .updateRegistration(state.moveDTO.customerReference, registration?.id, registration, params)
      .pipe(
        take(1),
        this.getCatchErrorOperatorFunction(),
        tap((savedReg: MoveInRegistration) => {
          this.resetResponseError();
          if (savedReg) {
            this.updateData({
              registration: savedReg,
              form: BackendToFrontendMapperService.map(
                state?.moveDTO,
                newOccupantReg ? null : savedReg,
                newOccupantReg ? savedReg : null
              ),
            });
          }
        })
      );
  }

  setAmendment(moveDTO: MoveDTO): Observable<MoveInRegistration> {
    moveDTO = moveDTO || this.state$.value.moveDTO;
    const reference: string = moveDTO.customerReference;
    const siteId: string = this.getMoveSiteId();
    const deliveryPoints: DeliveryPoint[] = moveDTO?.sites?.[0]?.deliveryPoints;
    const energies: EnergyType[] = deliveryPoints.map((deliveryPoint: DeliveryPoint) => deliveryPoint.energyType);
    const energyType: EnergyType = energies.includes(EnergyType.ELECTRICITY)
      ? energies.includes(EnergyType.GAS)
        ? EnergyType.DUAL
        : EnergyType.ELECTRICITY
      : EnergyType.GAS;
    const params: HttpParams = new HttpParams().set('loader', 'false').set('energyType', energyType);
    return this.moveFormService.createAmendment(reference, siteId, moveDTO, params).pipe(
      switchMap((registration: MoveInRegistration) => {
        const params: HttpParams = new HttpParams().set('loader', 'false').set('linkType', LinkType.MOVE_IN);
        return this.moveFormService.linkMoveInRegistration(reference, siteId, moveDTO, registration?.id, params).pipe(
          map(() => {
            this.updateData({ registration });
            return registration;
          })
        );
      })
    );
  }

  getSiteAddress(site: Site): Address {
    return {
      street: site?.streetName,
      streetNumber: site?.streetNumber,
      zip: site?.zipCode,
      box: site?.boxNumber,
      city: site?.town,
      country: site?.country,
    };
  }

  getContact(contact: ContactDetails): Contact {
    return {
      lastName: contact?.lastName,
      firstName: contact?.firstName,
      email: contact?.email,
      civility: contact?.civility,
      language: contact?.language,
      birthDate: contact?.birthDate && moment(contact?.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
      homePhone: contact?.phone,
      mobilePhone: contact?.mobile,
      principal: true,
    };
  }

  getAvailableMeters(): Observable<ApiResponse<Meter[]>> {
    const state: MoveState = this.state$.value;
    const siteId: string = this.getMoveSiteId();
    const params: HttpParams = new HttpParams().set('loader', 'false');
    return this.moveFormService
      .getAvailableMeters(state.moveDTO.customerReference, siteId, state.moveDTO.id, params)
      .pipe(
        take(1),
        catchError(this.errorHandlerService.handleError<Meter[]>),
        map((data: Meter[]): ApiResponse<Meter[]> => ({ data, loading: false }))
      );
  }

  getPreSwitchLight(forceRebuild: boolean = true): Observable<PreSwitchLight[]> {
    const state: MoveState = this.state$.value;
    const siteId: string = this.getMoveSiteId();
    const params: HttpParams = new HttpParams().set('loader', 'false').set('forceRebuild', forceRebuild);
    return this.moveFormService
      .preSwitchLight(state.moveDTO.customerReference, siteId, state.moveDTO.id, params)
      .pipe(take(1));
  }

  startMove(siteId: string): void {
    const initial: MoveState = structuredClone(INITIAL_MOVE_STATE);
    initial.moveDTO.sites[0].siteId = siteId;
    this.state$.next(initial);
    this.router.navigate([MoveFormFacade.FORM_URL]);
  }

  resumeMove(reference: string, siteId: string, moveId: string): void {
    const queryParams: Params = { moveId };
    const moveLocalData: MoveState = this.getMoveLocalData(moveId);
    const route: string = moveLocalData?.currentStepUrl || '';
    this.fetchMoveData(reference, siteId, moveId).subscribe(({ moveDTO, registration, newOccupantRegistration }) => {
      this.setMoveState(moveLocalData, moveDTO, registration, newOccupantRegistration);
      this.router.navigate([`${MoveFormFacade.FORM_URL}/${route}`], { queryParams });
    });
  }

  resumeMoveFromPageReload(moveId: string): void {
    // get local data and reset response error
    const moveLocalData = this.getMoveLocalData(moveId);

    if (!moveLocalData) {
      this.redirectToMoveDashboard();
      return;
    }

    // reset response error after reloading
    const { responseError, ...moveLocalDataWithoutResponseError } = moveLocalData;

    const siteId = moveLocalDataWithoutResponseError?.moveDTO?.sites[0].siteId;
    this.referenceService
      .getActiveReference()
      .pipe(
        take(1),
        switchMap((reference: string) => this.fetchMoveData(reference, siteId, moveId)),
        this.getCatchErrorOperatorFunction()
      )
      .subscribe(({ moveDTO, registration, newOccupantRegistration }): void => {
        if (!moveDTO) {
          this.redirectToMoveDashboard();
        } else {
          this.setMoveState(moveLocalDataWithoutResponseError, moveDTO, registration, newOccupantRegistration);
        }
      });
  }

  setRoute(route: MoveFormPaths[]): void {
    this.setState(
      (currentState: MoveState): MoveState => ({
        ...currentState,
        route,
        lastStepUrlReached: route[0],
        numberOfStep: route.length,
      })
    );
  }

  next(): void {
    const state: MoveState = this.state$.value;

    if (state.responseError) {
      return;
    }

    const lastStepReachedIndex: number = state.route.findIndex(
      (step: MoveFormPaths): boolean => step === state.lastStepUrlReached
    );
    const currentStepIndex: number = this.state$.value.currentStep;
    const nextStepIndex: number = currentStepIndex + 1;
    const nextUrl: MoveFormPaths = state.route[nextStepIndex];

    this.setState(
      (currentState: MoveState): MoveState => ({
        ...currentState,
        currentStepUrl: nextUrl,
        currentStep: nextStepIndex,
        lastStepUrlReached: lastStepReachedIndex < nextStepIndex ? nextUrl : state.lastStepUrlReached,
        isLastStepReached: lastStepReachedIndex === nextStepIndex,
        numberOfStepExecuted: state.numberOfStepExecuted + 1,
      })
    );

    this.saveStateToLocalStorage();
  }

  previous(): void {
    const state: MoveState = this.state$.value;
    const currentStepIndex: number = this.state$.value.currentStep;
    const previousStepIndex: number = currentStepIndex - 1;
    const previousUrl: MoveFormPaths = state.route[previousStepIndex];

    this.setState((currentState: MoveState) => ({
      ...currentState,
      currentStepUrl: previousUrl,
      currentStep: previousStepIndex,
      numberOfStepExecuted: state.numberOfStepExecuted - 1,
      isLastStepReached: false,
    }));

    this.saveStateToLocalStorage();
  }

  readonlyModeNav(action: 'next' | 'prev') {
    this.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        action === 'prev' ? this.previous() : this.next();
        this.loader$.next(LoaderStatus.LOADED);
      });
  }

  updateData(data: Partial<MoveState>): void {
    this.setState(
      (state: MoveState): MoveState => ({
        ...state,
        ...data,
      })
    );
  }

  isFirstStep(): boolean {
    const state: MoveState = this.state$.value;
    const [firstStep] = state.route;
    return state.currentStepUrl === firstStep;
  }

  isLastStep(): boolean {
    const state: MoveState = this.state$.value;
    const [lastStep] = state.route.slice(-1);
    return state.currentStepUrl === lastStep;
  }

  getStatus(reference: string): Observable<ApiResponse<SiteStatusCuzoApi[]>> {
    return this.moveFormService.getStatus(reference).pipe(
      map((data: SiteStatusCuzoApi[]): SiteStatusCuzoApi[] => {
        return data
          ?.map((site: SiteStatusCuzoApi): SiteStatusCuzoApi => {
            const viewStatus: ViewStatus = this.getViewStatus(site.moveData);
            return { ...site, viewStatus };
          })
          .sort(
            (a: SiteStatusCuzoApi, b: SiteStatusCuzoApi) => priorityOrder[a.viewStatus] - priorityOrder[b.viewStatus]
          );
      }),
      map(
        (data: SiteStatusCuzoApi[]): ApiResponse<SiteStatusCuzoApi[]> => ({
          data,
          loading: false,
        })
      ),
      catchError(this.errorHandlerService.handleError<SiteStatusCuzoApi[]>)
    );
  }

  uploadMoveOutDREFile(
    reference: string,
    file: File,
    dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi
  ): Observable<any> {
    const siteId: string = this.getMoveSiteId();
    const moveId: string = this.getMoveId();
    const params: HttpParams = new HttpParams().set('dreCompletionStatus', dreCompletionStatus).set('loader', 'false');
    return this.moveFormService.uploadHandoverMoveOutDocument(reference, siteId, moveId, file, params);
  }

  uploadMoveInDREFile(
    reference: string,
    moveInId: string,
    file: File,
    dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi
  ): Observable<any> {
    const params: HttpParams = new HttpParams()
      .set('dreCompletionStatus', dreCompletionStatus)
      .set('type', 'HANDOVERDOC')
      .set('loader', 'false');
    return this.moveFormService.uploadHandoverMoveInDocument(reference, moveInId, file, params);
  }

  submitMove(): Observable<unknown> {
    return this.moveFormService
      .finalizeMoveOut(this.state$.value.moveDTO.customerReference, this.state$.value.moveDTO.id)
      .pipe(
        this.getCatchErrorOperatorFunction(),
        tap(() => this.resetResponseError())
      );
  }

  emitDataSaving() {
    const now = moment().unix();
    this.saveFormForLater.save(now);
  }

  saveStateToLocalStorage() {
    const state: MoveState = this.state$.value;
    if (state?.moveDTO?.id) {
      localStorage.setItem(state.moveDTO.id, JSON.stringify(this.state$.value));
    }
  }

  createEmptyFile(name: string): File {
    return this.utilsService.createEmptyFileWithName(name);
  }

  get saveFormListener() {
    return this.saveFormForLater.save$;
  }

  redirectToMoveDashboard() {
    this.router.navigate([MoveFormFacade.MOVE_URL]);
  }

  private getViewStatus(moveData: MoveDataCuzoApi[]): ViewStatus {
    const noMove: boolean = moveData.every(
      (data: MoveDataCuzoApi): boolean => data.status === MoveDataStatusEnumCuzoApi.NOT_INITIATED
    );
    const allDone: boolean = moveData.every(
      (data: MoveDataCuzoApi): boolean => data.status === MoveDataStatusEnumCuzoApi.COMPLETED
    );
    const allMove: boolean = moveData.every(
      (data: MoveDataCuzoApi): boolean => data.status !== MoveDataStatusEnumCuzoApi.NOT_INITIATED
    );
    if (noMove) {
      return ViewStatus.NO_MOVE;
    }
    if (allDone) {
      return ViewStatus.ALL_DONE;
    }
    if (allMove) {
      return ViewStatus.ALL_MOVE;
    }
    return ViewStatus.PART_MOVE;
  }

  public getCatchErrorOperatorFunction(): OperatorFunction<unknown, unknown> {
    return catchError((error) => {
      this.setState(
        (state: MoveState): MoveState => ({
          ...state,
          ...{ responseError: true },
        })
      );

      this.loader$.next(LoaderStatus.LOADED);

      return throwError(() => error);
    });
  }

  private resetResponseError(): void {
    this.setState(
      (state: MoveState): MoveState => ({
        ...state,
        ...{ responseError: false },
      })
    );
  }

  private getMoveId(): string {
    const state: MoveState = this.state$.value;
    return state?.moveDTO?.id;
  }

  private getMoveSiteId(): string {
    const sites: MoveSite[] = this.state$.value.moveDTO.sites;
    return sites?.length ? sites[0].siteId : null;
  }

  private setState(updateFn: (currentState: MoveState) => MoveState): void {
    const newState: MoveState = updateFn(this.state$.value);
    this.state$.next(newState);
  }

  private setMoveState(
    moveLocalData: MoveState,
    moveDTO: MoveDTO,
    registration: MoveInRegistration,
    registrationNewOccupant: MoveInRegistration
  ): void {
    this.setState((currentState: MoveState) => ({
      ...currentState,
      ...moveLocalData,
      moveDTO,
      registration,
      registrationNewOccupant,
      readonly: moveDTO?.status !== MoveStatus.INITIATED,
      form: BackendToFrontendMapperService.map(moveDTO, registration, registrationNewOccupant),
    }));
  }

  private getMoveLocalData(moveId: string): MoveState {
    return JSON.parse(localStorage.getItem(moveId));
  }

  private fetchMoveData(
    reference: string,
    siteId: string,
    moveId: string
  ): Observable<{ moveDTO: MoveDTO; registration: MoveInRegistration; newOccupantRegistration: MoveInRegistration }> {
    return this.moveFormService.get(reference, siteId, moveId).pipe(
      take(1),
      switchMap((moveDTO: MoveDTO) =>
        forkJoin({
          moveDTO: of(moveDTO),
          registration: iif(
            () => !!moveDTO.moveChainId,
            this.getRegistration(moveDTO.id, reference, LinkType.MOVE_IN, true),
            of(null)
          ),
          newOccupantRegistration: iif(
            () => !!moveDTO.knowBuyer,
            this.getRegistration(moveDTO.id, reference, LinkType.NEW_BUYER, true),
            of(null)
          ),
        })
      )
    );
  }

  public buildNewOccupantRegistration(
    customerType: CustomerType,
    contact: Contact,
    site: MoveSite
  ): MoveInRegistration {
    const registration: MoveInRegistration = {
      contact,
      company:
        customerType === CustomerType.b2b ? { companyName: null, corporateName: null, companyNumber: null } : null,
      sites: [site],
      salesContext: 'OUTBOUND',
      subSalesContext: 'MOVE_REPRENEUR',
      agentCode: this.state$.value.moveDTO.agentCode,
      status: MoveStatus.INITIATED,
      segment: customerType,
      billingInfo: {
        electronicInvoicing: true,
        useDeliveryAddress: customerType === CustomerType.b2c,
        paymentMethod: PaymentMethod.BANK_DOMICILIATION,
        billingMethodType: BillingMethod.MONTHLY,
        address: {
          country: 'BE',
        },
      },
      moveChainId: this.state$.value.moveDTO.moveChainId,
      source: 'MOVE',
    };

    return registration;
  }
}
