import { InvoicingMethod, MoveMeter } from '@app/core/state/move.state';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { EnergyType } from '../../consumption/models/consumption.interface';

export interface Address {
  id?: string;
  identificationId?: string;
  street?: string;
  streetNumber?: string;
  zip?: string;
  box?: string;
  city?: string;
  country?: string;
}

export interface DeliveryPoint {
  id?: string;
  energyType?: EnergyType;
  code?: string;
  requestedServiceDate?: string;
  customerWillingServiceDate?: string;
  marketIlcDate?: string;
  moving?: boolean;
  currentSupplier?: string;
  switchType?: SwitchType;
  switchStatus?: string;
  lastSwitchStatusChange?: string;
  dgo?: string;
  readingType?: string;
  averagePeak?: number;
  billingMeterType?: string;
  productCode?: string;
  currentProductCode?: string;
  productCommercialCode?: string;
  productContractDuration?: number;
  switchTypeDeSynchronisationForced?: boolean;
  dreDocumentStatus?: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  currentContractId?: string;
  contractId?: string;
  idEan?: string;
  uuid?: string;
  meters?: MoveMeter[];
  documents?: any[];
  consumptions?: any[];
}

export enum SwitchType {
  MOVING = 'MOVING',
  NEWBINDINGOROPENCLOSEDMETER = 'NEWBINDINGOROPENCLOSEDMETER',
  MOVINGANDNEWBINDING = 'MOVINGANDNEWBINDING',
}

export interface Site {
  id?: string;
  address?: Address;
  account?: string;
  deliveryPoints?: DeliveryPoint[];
  services?: any[];
  energyContract?: string;
  trustSiteAddress?: boolean;
  index?: number;
  constructionDate?: string;
  heatingType?: string;
  hasSolarPanels?: boolean;
  solarPanelsInstalationDate?: string;
  hasElectricVehicle?: boolean;
  hasInjectionTariff?: boolean;
  solarPanelKVA?: number;
  peakSolarPower?: number;
  solarPanelsInstallationDateStarting2024?: boolean;
  domiciled?: boolean;
  urgentSwitch?: boolean;
  confirmMove?: boolean;
  inhabitantType?: string;
  moveOutId?: string;
  siteId?: string;
}

export interface ProcessDetail {
  processId?: string;
  processName?: string;
  businessKey?: string;
  instanceId?: string;
}

export interface Contact {
  id?: string;
  civility?: string;
  language?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  homePhone?: string;
  mobilePhone?: string;
  birthDate?: string;
}

export interface MoveInRegistration {
  id?: string;
  contact?: Contact;
  customerReference?: string;
  validationDate?: string;
  missingFormSubmitDate?: string;
  missingFormSendDate?: string;
  businessWorkingList?: string;
  step?: string;
  segment?: CustomerType;
  source?: string;
  nursingStatus?: string;
  nursingRemarks?: string;
  nursingDate?: string;
  nursingOperator?: string;
  customerRemarks?: string;
  operatorRemarks?: string;
  agentCode?: string;
  initialOperator?: string;
  initialEncodingGroup?: string;
  createdBy?: string;
  modifiedBy?: string;
  createdDate?: string;
  modifiedDate?: string;
  archiveReason?: string;
  archiveSubReason?: string;
  postponeReason?: string;
  postponedSubReason?: string;
  postponeDate?: string;
  company?: Company;
  billingInfo?: BillingInfo;
  processDetail?: ProcessDetail;
  moveChainId?: string;
  activationDate?: string;
  sites?: Site[];
  documents?: any[];
  communications?: any[];
  signatureDate?: string;
  status?: string;
  subStatus?: string;
  sourceId?: string;
  salesContext?: string;
  subSalesContext?: string;
  campaign?: string;
  leadOrigin?: string;
  sponsorReference?: string;
  creditScore?: number;
  context?: string;
  screeningScore?: number;
  simulationId?: string;
  offerEncodingType?: string;
  fieldManualValidationDone?: boolean;
  duplicateRegistrationId?: string;
  minorEmancipationDocumentProvided?: boolean;
  houseHoldCertificateStatus?: string;
  checkFraudUnjustified?: boolean;
  checkFraudUnjustifiedReason?: string;
  checkScreeningScoreUnjustified?: boolean;
  checkScreeningScoreUnjustifiedReason?: string;
  socialTariff?: boolean;
  createCustomerZoneAccount?: boolean;
  creditCheckWarrantyPaymentDate?: string;
  creditCheckSepaReceived?: boolean;
  creditCheckEscalationAsked?: boolean;
  moveInPriority?: boolean;
  bisNodeKey?: string;
  bisNodeProbability?: number;
  tariffDate?: string;
  acceptExistingCustomer?: boolean;
  consents?: any[];
  moves?: string;
  displayMode?: string;
  eIDCardProvided?: boolean;
}
export interface Company {
  companyName?: string;
  corporateName?: string;
  companyNumber?: string;
}

export interface BillingInfo {
  id?: string;
  iban?: string;
  bic?: string;
  bankName?: string;
  billingLot?: number;
  paymentMethod?: PaymentMethod;
  electronicInvoicing?: boolean;
  invoiceSendingType?: InvoicingMethod;
  billingMethodType?: BillingMethod;
  address?: Address;
  useDeliveryAddress?: boolean;
  refundIban?: string;
  refundBic?: string;
  refundBankName?: string;
  oneInvoicePerEAN?: boolean;
}

export enum PaymentMethod {
  BANK_TRANSFER = 'BANK_TRANSFER',
  BANK_DOMICILIATION = 'BANK_DOMICILIATION',
  BANK_DOMICILIATION_AND_BANK_TRANSFER = 'BANK_DOMICILIATION_AND_BANK_TRANSFER',
  ALL = 'ALL',
}

export enum BillingMethod {
  MONTHLY = 'MONTHLY',
  BIMONTHLY = 'BIMONTHLY',
  TRIMONTHLY = 'TRIMONTHLY',
  ALL = 'ALL',
}
