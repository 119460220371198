<div [formGroup]="addressFormGroup">
  <div class="my-4">
    <app-input-error
      [cssClass]="'error inline-block w-full text-primary text-sm'"
      [condition]="submittedForm && addressFormGroup.invalid && (addressFormGroup.touched || addressFormGroup.dirty)"
      [errorMessage]="'errorMessages.move.fieldsBelowAreRequired'"
    ></app-input-error>
  </div>
  <div class="flex flex-wrap md:flex-nowrap gap-4 sm:gap-8 mb-4">
    <div class="w-full md:w-3/5 flex-auto form-element input-text mt-4" [class.errors]="isControlInValid('address')">
      <label>{{ 'pages.move.address.street' | translate }}</label>
      <input type="text" formControlName="address" placeholder="Nom de la rue" />
    </div>
    <p class="flex-1 basis-1/5 form-element input-text mt-4" [class.errors]="isControlInValid('number')">
      <label>{{ 'pages.move.address.streetNumber' | translate }}</label>
      <input onlyNumber type="text" formControlName="number" placeholder="123" />
    </p>
    <p class="flex-1 basis-1/5 form-element input-text mt-4">
      <label>{{ 'pages.move.address.box' | translate }}</label>
      <input type="text" formControlName="box" placeholder="A1" />
    </p>
  </div>
  <div class="flex flex-col sm:flex-row gap-4 sm:gap-8 mb-4">
    <p class="w-full sm:w-1/3 form-element input-text mt-4" [class.errors]="isControlInValid('zipCode')">
      <label>{{ 'pages.move.address.zipCode' | translate }}</label>
      <input onlyNumber type="text" formControlName="zipCode" placeholder="4000" />
    </p>
    <p class="w-full sm:w-2/3 form-element input-text mt-4" [class.errors]="isControlInValid('locality')">
      <label>{{ 'pages.move.address.locality' | translate }}</label>
      <input type="text" formControlName="locality" placeholder="Liège" />
    </p>
  </div>
  <div class="flex gap-8" [class.errors]="isControlInValid('country')">
    <p class="w-full form-element input-text mt-4">
      <label>{{ 'pages.move.address.country' | translate }}</label>
      <input type="text" formControlName="country" />
    </p>
  </div>
</div>
