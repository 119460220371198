import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import { SharedModule } from '@app/shared/shared.module';
import { Meter } from '@app/core/state/move.state';
import { TranslateModule } from '@ngx-translate/core';
import { FormGroup } from '@angular/forms';
import { DeliveryPointService } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/delivery-point.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { DeliveryPoint } from '@app/modules/customer-zone/move/models/movein.interface';

@Component({
  selector: 'app-meters-control',
  standalone: true,
  imports: [CommonModule, AlertComponent, SharedModule, TranslateModule],
  templateUrl: './meters-control.component.html',
  styleUrls: ['./meters-control.component.scss'],
})
export class MetersControlComponent implements OnInit {
  protected readonly AlertType = AlertType;
  protected readonly InputMeterStyle = InputMeterStyle;
  @Input() eanFormGroup: FormGroup;
  @Input() loadingState: Map<string, boolean>;
  @Input() meter: Meter;
  deliveryPointMap: Map<string, DeliveryPoint>;

  constructor(private deliveryPointService: DeliveryPointService) {}

  ngOnInit(): void {
    this.deliveryPointService.deliveryPointSubject$.subscribe(
      (deliveryPoints: Map<string, DeliveryPoint>) => (this.deliveryPointMap = deliveryPoints)
    );
  }
}

_('pages.move.myMeters.mandatoryIndexesWarning');
_('pages.move.myMeters.pswlFailure');
