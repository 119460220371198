import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MainFacade } from '@app/core/facade/main.facade';
import { Content } from '@app/shared/models/content.interface';
import { Observable, Subject, filter } from 'rxjs';

@Component({
  selector: 'app-warning-banner',
  templateUrl: './warning-banner.component.html',
  styleUrls: ['./warning-banner.component.scss'],
})
export class WarningBannerComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() body: string;
  @Input() url: string;
  @Input() visibility: boolean = true;

  public content$: Observable<Content>;
  private notifier$: Subject<void> = new Subject();

  constructor(private facade: MainFacade) {}

  ngOnInit(): void {
    this.facade.reference$.pipe(filter((reference: string) => !!reference)).subscribe((reference: string) => {
      this.content$ = this.facade.loadContent(reference);
    });
  }

  ngOnDestroy(): void {
    this.notifier$.next();
    this.notifier$.complete();
  }
}
