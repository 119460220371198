import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { BillingModule } from '../billing/billing.module';
import { InvoicesModule } from '../invoices/invoices.module';
import { RouterModule } from '@angular/router';
import { ContractDetailComponent } from '../contracts/components/modals/contract-detail/contract-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { SharedModule } from '../../../shared/shared.module';
import { BlogModule } from '../../../shared/modules/blog/blog.module';
import { ContractsIconsComponent } from './components/contracts-icons/contracts-icons.component';
import { ListServicesComponent } from './components/list-services/list-services.component';
import { UpdateContactEmailComponent } from './components/update-contact-email/update-contact-email.component';
import { TrackAndTraceComponent } from './components/track-and-trace/track-and-trace.component';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { ConsumptionFeatureBannerComponent } from './components/consumption-feature-banner/consumption-feature-banner.component';
import { PaymentBalanceComponent } from './components/payment-balance/payment-balance.component';
import { PaymentsListComponent } from './components/payments-list/payments-list.component';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';
import { PaymentInformationsComponent } from './components/payment-informations/payment-informations.component';
import { OpenInvoicesComponent } from '@app/modules/customer-zone/dashboard/components/open-invoices/open-invoices.component';
import { WarningBannerComponent } from '@app/shared/components/banners/warning-banner/warning-banner.component';
import { GiveawayBannerComponent } from '@app/shared/components/banners/giveway-banner/giveaway-banner.component';

@NgModule({
  declarations: [
    DashboardComponent,
    ContractDetailComponent,
    QuickLinksComponent,
    ContractsIconsComponent,
    ListServicesComponent,
    UpdateContactEmailComponent,
    TrackAndTraceComponent,
    ConsumptionFeatureBannerComponent,
    GiveawayBannerComponent,
    WarningBannerComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    TranslateModule,
    BillingModule,
    InvoicesModule,
    BlogModule,
    RouterModule,
    NgbModule,
    SharedModule,
    AnalyticsModule,
    NgOptimizedImage,
    PaymentBalanceComponent,
    SlideToggleDirective,
    PaymentsListComponent,
    PaymentInformationsComponent,
    OpenInvoicesComponent,
  ],
})
export class DashboardModule {}

_('pages.dashboard.pageTitle');
_('pages.dashboard.sponsorship.content');
_('pages.dashboard.sponsorship.linkLabel');
_('components.estimatedIndexDetail.alert.encode.title');
_('components.estimatedIndexDetail.alert.encode.content');
_('components.estimatedIndexDetail.alert.encode.link');
