import { Direction, MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import * as FormUtils from '@app/shared/utils/utils.form';
import { RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { FormGroup } from '@angular/forms';
export enum MeterTypeOptions {
  MONO = 'pages.move.newMeters.smartMeterAndMeterType.MONO',
  BI = 'pages.move.newMeters.smartMeterAndMeterType.BI',
  SMART_MONO = 'pages.move.newMeters.smartMeterAndMeterType.SMART_MONO',
  SMART_BI = 'pages.move.newMeters.smartMeterAndMeterType.SMART_BI',
}

export interface IndexesUI {
  timeFrame: RegisterType;
  type: Direction;
  unit?: string | null;
  decimal?: string | null;
  value: string | null;
}

export interface ElectricityMeterUI extends GasMeterUI {
  smartMeterAndMeterType?: string | null;
  smartMeter?: boolean;
  exclusiveNight?: boolean;
  peakPower?: number | null;
  hasSolarPanels?: boolean;
  solarPanelsKva?: number | null;
  solarPanelsInstallationDateStarting2024?: boolean;
  injectionTariff?: boolean;
}

export interface GasMeterUI {
  noMeter?: boolean;
  ean?: string | null;
  number?: string | null;
  meterType?: MeterType | null;
  indexes?: Array<IndexesUI>;
}

export interface MetersForm {
  electricity: FormGroup<ElectricityMeterFormGroup>;
  gas: FormGroup<GasMeterFormGroup>;
}

export interface NewMetersUI {
  energyType: {
    date: string;
  };
  meters: {
    electricity?: ElectricityMeterUI;
    gas?: GasMeterUI;
  };
}

export type NewMetersFormFormGroup = FormUtils.FormGroupControls<NewMetersUI>;
export type ElectricityMeterFormGroup = FormUtils.FormGroupControls<ElectricityMeterUI>;
export type GasMeterFormGroup = FormUtils.FormGroupControls<GasMeterUI>;
export type IndexesFormGroup = FormUtils.FormGroupControls<IndexesUI>;
