<div class="details">
  <div class="box box--radius box--padding mb-16 details__building">
    <div class="text-center card-primary md:text-left">
      <img
        [src]="energyIcon"
        class="inline-block w-24 h-24 mb-8 md:mb-0"
      />
      <div class="w-full align-middle inline-block text-center md:text-left md:ml-10 md:w-auto">
        <strong>{{ 'general.eanCode' | translate }}</strong> {{ estimatedIndex.ean }} <br />
        <strong>{{ 'general.meterNumber' | translate }}{{ 'general.dotSeparator' | translate }}</strong>
        <ng-container *ngIf="estimatedIndex?.meters?.length">
          {{ estimatedIndex.meters[0].meterNumber }} <br />
        </ng-container>
        <ng-container *ngIf="site$ | async as site">
          <strong>{{ 'general.address.label' | translate }}</strong> {{ site.formattedAddress }} <br />
        </ng-container>
        <strong>{{ 'components.estimatedIndexDetail.corrections' | translate }}</strong>
        {{ estimatedIndex.indexDate | date: 'd/MM/yyyy' }}
      </div>
    </div>
  </div>

  <div *ngIf="showForm" class="box box--radius box--padding mb-16">
    <form (ngSubmit)="submit()" novalidate [formGroup]="estimatedIndexForm" class="gc-form">
      <div class="form__component meter-input">
        <ng-container *ngFor="let metersControl of meters.controls; let j = index">
          <ng-container *ngFor="let indexControl of metersControl.get('indexes')['controls']; let i = index">
            <app-input-meter-index
              [newType]="estimatedIndex.meters[j].indexes[i]['newRegister']"
              [type]="estimatedIndex.meters[j].indexes[i].register | lowercase"
              [value]="estimatedIndex.meters[j].indexes[i].value"
              [control]="indexControl"
              [date]="estimatedIndex.indexDate"
              [unit]="energyUnit"
              [label]="estimatedIndex.meters[j].indexes[i].label"
              [tooltip]="estimatedIndex.meters[j].indexes[i].tooltip"
            >
            </app-input-meter-index>
            <div class="form__error form__error--highlighted">
              <div *ngIf="indexControl.get('unit').errors?.required && indexControl.get('unit').touched">
                {{ 'errorMessages.required' | translate }}
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <fc-input-date
        class="w-full inline-block md:mt-8"
        [control]="estimatedIndexForm.get('date')"
        [label]="'components.estimatedIndexDetail.form.date' | translate"
        [minDate]="minDate"
        [maxDate]="maxDate"
      >
      </fc-input-date>
      <button
        type="submit"
        [attr.disabled]="!estimatedIndexForm.valid || estimatedIndexForm.pristine ? '' : null"
        [class.not-submittable]="!estimatedIndexForm.valid || estimatedIndexForm.pristine"
        class="btn btn-primary font-bold inline-block w-full sm:w-auto mt-8"
        [analyticsEvent]="'estimatedIndex:click:submit-estimated-index'"
      >
        {{ 'general.confirm' | translate }}
      </button>
    </form>
  </div>
</div>
