import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MoveFormPaths } from '@app/core/models/paths';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { NewOccupant } from '../../new-occupant/new-occupant.component';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';

@Component({
  selector: 'app-summary-new-occupant',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-new-occupant.component.html',
  styleUrls: ['./summary-new-occupant.component.scss'],
})
export class SummaryNewOccupantComponent {
  @Input() registration: MoveInRegistration;
  @Input() knowBuyer: boolean;
  @Output() editEmitter: EventEmitter<MoveFormPaths> = new EventEmitter<MoveFormPaths>();
  readonly CustomerType = CustomerType;

  edit() {
    const step = MoveFormPaths.newOccupant;
    this.editEmitter.emit(step);
  }
}
