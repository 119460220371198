<div class="loader-container" *ngIf="(!lastMeterIndex && !getError) || formSubmitting">
  <app-loader></app-loader>
</div>

<div class="mt-8" *ngIf="!lastMeterIndex && getError && !formSubmitting">
  {{ 'errorMessages.serverError' | translate }}
</div>

<div class="meter-form" *ngIf="lastMeterIndex && !getError && !formSubmitting">
  <div class="mt-4 mb-6 sm:mb-8 sm:mt-10">
    {{ 'components.consumption.index.' + (editIndex ? 'editIndex' : 'intro') | translate }}
  </div>
  <ng-container *ngFor="let indexControl of indexes.controls; let i = index">
    <span
      *ngIf="i === 0 || lastMeterIndex.indexes[i - 1]?.meterNumber !== lastMeterIndex.indexes[i]?.meterNumber"
      class="w-full inline-block text-base mb-6 sm:mt-6 sm:mb-8 sm:text-lg"
    >
      <u>
        {{ 'general.meterNumber' | translate }}{{ 'general.dotSeparator' | translate }}
        {{ lastMeterIndex.indexes[i]?.meterNumber }}
      </u>
    </span>
    <app-input-meter-index
      [modal]="true"
      [direction]="lastMeterIndex.indexes[i].direction"
      [type]="lastMeterIndex.indexes[i].timeFrame | lowercase"
      [value]="lastMeterIndex.indexes[i].value"
      [unit]="this.contract.type === energyType.ELECTRICITY ? 'kwh' : 'm³'"
      [date]="lastMeterIndex.indexDate"
      [control]="indexControl"
      [label]="
        'components.estimatedIndexDetail.form.meterInput.' +
          lastMeterIndex.indexes[i].timeFrame +
          '.' +
          lastMeterIndex.indexes[i].direction +
          '.label' | translate
      "
      [tooltip]="
        'components.estimatedIndexDetail.form.meterInput.' +
          lastMeterIndex.indexes[i].timeFrame +
          '.' +
          lastMeterIndex.indexes[i].direction +
          '.tooltip' | translate
      "
      [errors]="{
        higherThanAverage: indexControl.get('unit').errors?.higherThanAverage && 'errorMessages.higherThanAverage'
      }"
    >
    </app-input-meter-index>
  </ng-container>

  <fc-input-date
    class="w-full inline-block md:mt-8"
    [editIndex]="editIndex"
    [control]="control.get('date')"
    [label]="'components.estimatedIndexDetail.form.date' | translate"
    [maxDate]="today"
    [minDate]="lastMeterIndex.indexDate"
  >
  </fc-input-date>
  <button
    type="button"
    (click)="submit()"
    [attr.disabled]="control.errors?.notValid ? '' : null"
    [class.not-submittable]="control.errors?.notValid"
    [analyticsEvent]="'myEnergyFlow:cta-click:send-reading'"
    class="btn btn-primary font-bold inline-block w-full mt-12 px-12 md:w-auto"
  >
    {{ 'general.send' | translate }}
  </button>
</div>
