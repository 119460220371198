import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MoveMeter } from '@app/modules/customer-zone/move/models/status.interface';
import { TranslateModule } from '@ngx-translate/core';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveDTO, MoveSite } from '@app/core/state/move.state';
import { DeliveryPoint } from '@app/modules/customer-zone/move/models/movein.interface';

@Component({
  selector: 'app-move-meter-tag',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './move-meter-tag.component.html',
  styleUrls: ['./move-meter-tag.component.scss'],
})
export class MoveMeterTagComponent implements OnInit {
  @Input() meters: MoveMeter[];
  readonly EnergyType = EnergyType;
  hasAtLeastOneMeterWithNumberDefined: boolean = false;

  constructor(public moveFormFacade: MoveFormFacade) {}

  ngOnInit(): void {
    this.moveFormFacade.moveDTO$.subscribe((moveDTO: MoveDTO) => {
      this.hasAtLeastOneMeterWithNumberDefined = moveDTO?.sites?.some((moveSite: MoveSite): boolean =>
        moveSite?.deliveryPoints?.some((deliveryPoint: DeliveryPoint) =>
          deliveryPoint?.meters?.some((moveMeter: MoveMeter) => moveMeter?.number !== null)
        )
      );
    });
  }
}
