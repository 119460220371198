<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.movingInfo.title' | translate }}
    </h2>
    <div>
      <app-dre-document
        [formGroup]="form"
        [uploadInProgress]="fileUploadInProgress"
        (fileEmitter)="onFileSelection($event)"
      >
      </app-dre-document>

      <div [formGroup]="form">
        <h3 class="text-2xl font-medium text-grey-dark mt-16">
          {{ 'pages.move.movingInfo.movingDate' | translate }}
        </h3>
        <div class="relative mt-8 z-10">
          <div
            [class.hidden]="form.get('dreStatus').value"
            class="absolute top-0 left-0 w-full h-full cursor-not-allowed rounded-2xl bg-black/10 z-10 grayscale"
          ></div>
          <date-picker-field
            formControlName="movingDate"
            [fieldLabel]="'pages.move.movingInfo.movingDateLabel' | translate"
            [dateRange]="serviceDateRange$ | async"
            (dateChanged)="onDateSelection($event)"
          >
          </date-picker-field>
        </div>
        <div
          *ngIf="form.get('movingDate').invalid && (form.get('movingDate').touched || form.get('movingDate').dirty)"
          class="error inline-block w-full text-primary text-sm"
        >
          {{ 'errorMessages.requiredField' | translate }}
        </div>
        <app-alert
          *ngIf="hasMissingMandatoryIndexes"
          class="inline-block mt-16 w-full"
          [flatDesign]="true"
          [alert]="{
          type: AlertType.info,
          content: {
            messageKey: 'pages.move.movingInfo.alert.missingIndexes',
          },
        }"
        ></app-alert>
      </div>
    </div>
  </div>
</div>
<app-navigation
  [stepValidity]="!hasMissingMandatoryIndexes && !fileUploadInProgress"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>
