import { ORDERED_QUESTION_STAY } from '@app/modules/customer-zone/move/components/move-form/move-form-config';
import { MoveFormPaths } from '@app/core/models/paths';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Address } from '@app/shared/models/address.interface';
import { PreSwitchLight } from '@app/modules/customer-zone/move/models/api.interface';
import {
  DocumentCuzoApi,
  UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import { Product } from '@app/modules/customer-zone/user/models/product.interface';
import { Direction, MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import {
  BillingInfo,
  DeliveryPoint,
  MoveInRegistration,
} from '@app/modules/customer-zone/move/models/movein.interface';
import { NewOccupant } from '@app/modules/customer-zone/move/components/move-form/steps/new-occupant/new-occupant.component';
import {
  ElectricityMeterUI,
  GasMeterUI,
} from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/new-meter.interface';

export enum MoveOutContext {
  PAPER_MAIL = 'PAPER_MAIL',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  E_MOVE = 'E_MOVE',
  VANISHING = 'VANISHING',
}
export enum MoveOutReason {
  MOVE = 'MOVE',
  DEATH = 'DEATH',
  NAME_CHANGE = 'NAME_CHANGE',
  OWNER = 'OWNER',
  B2C_TO_B2B = 'B2C_TO_B2B',
  B2B_TO_B2C = 'B2B_TO_B2C',
}
export enum MoveStatus {
  INITIATED = 'INITIATED',
}

export const INITIAL_MOVE_STATE: MoveState = {
  route: ORDERED_QUESTION_STAY,
  currentStepUrl: ORDERED_QUESTION_STAY[0],
  currentStep: 0,
  lastStepUrlReached: ORDERED_QUESTION_STAY[0],
  numberOfStep: ORDERED_QUESTION_STAY.length,
  isLastStepReached: true,
  numberOfStepExecuted: 0,
  form: null,
  createdDate: new Date(),
  readonly: false,
  moveDTO: {
    customerReference: null,
    context: MoveOutContext.E_MOVE,
    reason: MoveOutReason.MOVE,
    sites: [
      {
        siteId: null,
      },
    ],
    newContract: false,
    amendmentField: null,
    indexCosigned: false,
    failReason: false,
    knowBuyer: false,
    failReasonCode: null,
    status: MoveStatus.INITIATED,
    source: 'MOVE',
    segment: CustomerType.b2c,
    contacts: [],
    agentCode: null,
  },
};

export interface MoveState {
  route: MoveFormPaths[];
  currentStepUrl?: MoveFormPaths;
  currentStep?: number;
  lastStepUrlReached?: MoveFormPaths;
  isLastStepReached?: boolean;
  numberOfStep: number;
  numberOfStepExecuted: number;
  form: MoveFormFrontend;
  createdDate: Date;
  moveDTO: MoveDTO;
  preSwitchLight?: PreSwitchLight[];
  ilcDate?: string;
  documents?: DocumentCuzoApi[];
  registration?: MoveInRegistration;
  registrationNewOccupant?: MoveInRegistration;
  readonly?: boolean;
  responseError?: boolean;
}

export interface MoveFormFrontend {
  supplier?: {
    amendmentField: boolean;
  };
  myMeter?: any; // Not being populated from localStorage
  movingInfo?: {
    dreStatus?: UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
    fileName?: any;
    movingDate?: string;
  };
  newAddress?: {
    newAddress?: {
      address?: string;
      number?: string;
      box?: string;
      zipCode?: string;
      locality?: string;
    };
    invoicingData?: {
      invoicingMethod: InvoicingMethod;
      hasDifferentInvoicingAddress?: boolean;
      invoicingAddress?: {
        address?: string;
        number?: string;
        box?: string;
        zipCode?: string;
        locality?: string;
      };
    };
    dre?: {
      dreStatus?: string;
      fileName?: string;
    };
  };
  newMeters: {
    step: number;
    energyType: any;
    meters: {
      electricity: ElectricityMeterUI;
      gas: GasMeterUI;
    };
  };
  newOccupant?: NewOccupant;
}

export interface MoveDTO {
  id?: string;
  customerReference: string;
  context: MoveOutContext;
  reason: MoveOutReason;
  sites: MoveSite[];
  documents?: any;
  archiveReason?: string;
  archiveSubReason?: string;
  newContract: boolean;
  amendmentField: boolean;
  indexCosigned: boolean;
  failReason: boolean;
  knowBuyer: boolean;
  failReasonCode: null;
  status: MoveStatus;
  source: 'MOVE';
  segment: CustomerType;
  ilcDate?: string;
  contacts: Contact[];
  moveChainId?: string;
  signatureDate?: string;
  billingInfo?: BillingInfo;
  agentCode?: string;
}

export interface Contact {
  id?: string;
  lastName?: string;
  firstName?: string;
  email?: string;
  civility?: string;
  language?: string;
  homePhone?: string;
  homePhonePrefix?: string;
  mobilePhone?: string;
  mobilePhonePrefix?: string;
  birthDate?: any;
  principal?: boolean;
}

export interface MoveSite {
  id?: string;
  siteId: string;
  address?: Address;
  deliveryPoints?: DeliveryPoint[];
}

export interface MoveMeter {
  number?: string;
  access?: boolean;
  type?: MeterType;
  registerOpen?: boolean;
  registers?: MoveRegister[];
  smartMeter?: boolean;
}

export interface MoveRegister {
  timeFrame?: RegisterType;
  type?: Direction;
  value?: string;
  extra?: MoveRegisterExtra;
  direction?: Direction;
  name?: string;
  index?: string;
  measurementNature?: string;
}

export interface MoveRegisterExtra {
  label?: string;
  tooltip?: string;
  unit?: string;
}

export interface MoveProduct extends Product {
  type: EnergyType;
}

export interface MetersByEnergy {
  electricity?: Meter[];
  gas?: Meter[];
}

export interface Meter {
  type?: EnergyType;
  energy?: EnergyType;
  name?: string;
  ean?: string;
  number?: string;
}

export enum InvoicingMethod {
  EMAIL = 'EMAIL',
  POST = 'POST',
  ELECTRONIC = 'ELECTRONIC',
}

export enum LinkType {
  MOVE_IN = 'MOVE_IN',
  NEW_BUYER = 'NEW_BUYER',
}
