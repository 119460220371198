<div
  class="{{ energyType | lowercase }} form-element mt-8"
  [formGroup]="eanFormGroup"
  [class.error]="eanFormGroup.invalid && eanFormGroup.touched"
>
  <div class="grid gap-8 mt-8">
    <app-expandable-checkbox-btn
      [name]="'selected'"
      [value]="meter?.ean"
      [selectedValue]="eanFormGroup.get('selected').value"
      (selectedValueChange)="onCheckboxChange($event)">
      <div class="flex items-center" label>
        <div class="pr-6">
          {{ 'pages.move.myMeters.meterNumber' | translate }} {{ meter?.number }}
          <span class="inline-block w-full text-sm text-grey-dark60/60">
            {{ 'general.eanCode' | translate }} : {{ meter.ean }}
          </span>
        </div>
        <img
          ngSrc="./assets/img/icons/standalone/default/{{ energyType | lowercase }}.svg"
          class="ml-auto"
          height="30"
          width="24"
        />
      </div>

      <app-meters-control [eanFormGroup]="eanFormGroup" [meter]="meter" [loadingState]="loadingState">
      </app-meters-control>
    </app-expandable-checkbox-btn>
  </div>
</div>
