import { Injectable } from '@angular/core';
import { InvoicingMethod, MoveDTO, MoveFormFrontend, MoveRegister } from '@app/core/state/move.state';
import {
  ReadInvoiceDataCuzoApi,
  ReadInvoiceDataPaymentMethodEnumCuzoApi,
  UploadDocumentParamsDreCompletionStatusEnumCuzoApi,
} from '@app/shared/models/cuzo-be-contract';
import {
  BillingInfo,
  BillingMethod,
  MoveInRegistration,
  SwitchType,
  PaymentMethod,
} from '@app/modules/customer-zone/move/models/movein.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { NewOccupant } from '../../components/move-form/steps/new-occupant/new-occupant.component';
import { IndexesUI } from '../../components/move-form/steps/new-meters/new-meter.interface';
import { MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { SMART } from '../../components/move-form/steps/new-meters/electricity-meter/electricity-meter.component';

@Injectable({
  providedIn: 'root',
})
export class BackendToFrontendMapperService {
  static map(
    moveDTO: MoveDTO,
    registration: MoveInRegistration = null,
    newOccupantRegistration: MoveInRegistration = null
  ): MoveFormFrontend {
    if (!moveDTO || !moveDTO?.id) {
      return { supplier: null, movingInfo: null, newAddress: null, newMeters: null, newOccupant: null };
    }
    const localForm = JSON.parse(localStorage.getItem(moveDTO?.id))?.form;
    return {
      supplier: this.mapSupplierStep(moveDTO, localForm),
      movingInfo: this.mapMovingInfoStep(moveDTO, localForm),
      newAddress: this.mapNewAddressStep(registration, localForm),
      newMeters: this.mapNewMetersStep(registration, localForm),
      newOccupant: this.mapNewOccupantStep(newOccupantRegistration, localForm),
    };
  }

  private static mapSupplierStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    return {
      amendmentField: moveDTO?.amendmentField ?? localForm?.supplier?.amendmentField,
    };
  }

  private static mapMovingInfoStep(moveDTO: MoveDTO, localForm: MoveFormFrontend) {
    const filename: string = moveDTO?.documents?.[moveDTO?.documents.length - 1]?.name || null;
    const dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi =
      moveDTO?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus;
    return {
      fileName: filename ?? localForm?.movingInfo?.fileName,
      dreStatus: dreStatus ?? localForm?.movingInfo?.dreStatus,
      movingDate: moveDTO?.ilcDate ?? localForm?.movingInfo?.movingDate,
    };
  }

  private static mapNewOccupantStep(registration: MoveInRegistration, localForm: MoveFormFrontend): NewOccupant {
    const newOccupantLocalStorage = localForm?.newOccupant;
    return {
      firstName: registration?.contact?.firstName ?? newOccupantLocalStorage?.firstName,
      lastName: registration?.contact?.lastName ?? newOccupantLocalStorage?.lastName,
      mobilePhone: registration?.contact?.firstName ?? newOccupantLocalStorage?.mobilePhone,
      customerType: registration?.segment ?? newOccupantLocalStorage?.customerType,
      hasNewOccupant: registration?.id ? true : newOccupantLocalStorage?.hasNewOccupant,
    };
  }

  private static mapNewAddressStep(registration: MoveInRegistration, localForm: MoveFormFrontend) {
    const filename: string = registration?.documents?.[registration?.documents.length - 1]?.name || null;
    return {
      newAddress: {
        address: registration?.sites?.[0]?.address?.street ?? localForm?.newAddress?.newAddress?.address,
        number: registration?.sites?.[0]?.address?.streetNumber ?? localForm?.newAddress?.newAddress?.number,
        box: registration?.sites?.[0]?.address?.box ?? localForm?.newAddress?.newAddress?.box,
        zipCode: registration?.sites?.[0]?.address?.zip ?? localForm?.newAddress?.newAddress?.zipCode,
        locality: registration?.sites?.[0]?.address?.city ?? localForm?.newAddress?.newAddress?.locality,
      },
      invoicingData: {
        invoicingMethod:
          registration?.billingInfo?.invoiceSendingType ?? localForm?.newAddress?.invoicingData?.invoicingMethod,
        hasDifferentInvoicingAddress:
          registration?.billingInfo?.useDeliveryAddress === false ??
          localForm?.newAddress?.invoicingData?.hasDifferentInvoicingAddress,
      },
      dre: {
        dreStatus:
          registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus ?? localForm?.newAddress?.dre?.dreStatus,
        fileName: filename ?? localForm?.newAddress?.dre?.fileName,
      },
    };
  }

  private static mapNewMetersStep(
    registration: MoveInRegistration,
    localForm: MoveFormFrontend
  ): MoveFormFrontend['newMeters'] {
    const [site] = registration?.sites || [];

    const elecDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.ELECTRICITY);
    const [elecMeter] = elecDeliveryPoint?.meters || [];
    const elecIndexes = elecMeter?.registers ?? localForm?.newMeters?.meters?.electricity?.indexes;
    const elecNoInstalledMeter = elecDeliveryPoint?.switchType === SwitchType.MOVINGANDNEWBINDING;
    const { meterType, isExclusiveNight } = this.mapMeterType(elecMeter?.type);
    const smartMeterAndMeterType = elecMeter?.smartMeter ? SMART + meterType : meterType ?? null;

    const gasDeliveryPoint = site?.deliveryPoints?.find((dp) => dp.energyType === EnergyType.GAS);
    const [gasMeter] = gasDeliveryPoint?.meters || [];
    const gasIndexes = gasMeter?.registers ?? localForm?.newMeters?.meters?.gas?.indexes;
    const gasNoInstalledMeter = gasDeliveryPoint?.switchType === SwitchType.MOVINGANDNEWBINDING;
    return {
      energyType: {
        date: site?.deliveryPoints?.[0]?.requestedServiceDate ?? localForm?.newMeters?.energyType?.date,
      },
      meters: {
        electricity: {
          noMeter: elecNoInstalledMeter ?? localForm?.newMeters?.meters?.electricity?.noMeter,
          ean: elecDeliveryPoint?.code ?? localForm?.newMeters?.meters?.electricity?.ean,
          number: elecMeter?.number ?? localForm?.newMeters?.meters?.electricity?.number,
          meterType: meterType ?? localForm?.newMeters?.meters?.electricity?.meterType,
          smartMeter: elecMeter?.smartMeter ?? localForm?.newMeters?.meters?.electricity?.smartMeter,
          smartMeterAndMeterType:
            smartMeterAndMeterType ?? localForm?.newMeters?.meters?.electricity?.smartMeterAndMeterType,
          exclusiveNight: isExclusiveNight ?? localForm?.newMeters?.meters?.electricity?.exclusiveNight,
          peakPower: site?.peakSolarPower ?? localForm?.newMeters?.meters?.electricity?.peakPower,
          hasSolarPanels: site?.hasSolarPanels ?? localForm?.newMeters?.meters?.electricity?.hasSolarPanels,
          solarPanelsKva: site?.solarPanelKVA ?? localForm?.newMeters?.meters?.electricity?.solarPanelsKva,
          solarPanelsInstallationDateStarting2024:
            site?.solarPanelsInstallationDateStarting2024 ??
            localForm?.newMeters?.meters?.electricity?.solarPanelsInstallationDateStarting2024,
          injectionTariff: site?.hasInjectionTariff ?? localForm?.newMeters?.meters?.electricity?.injectionTariff,
          indexes: elecIndexes && this.mapIndexes(elecIndexes),
        },
        gas: {
          noMeter: gasNoInstalledMeter ?? localForm?.newMeters?.meters?.gas?.noMeter,
          meterType: (gasMeter?.type as MeterType) ?? localForm?.newMeters?.meters?.gas?.meterType,
          ean: gasDeliveryPoint?.code ?? localForm?.newMeters?.meters?.gas?.ean,
          number: gasMeter?.number ?? localForm?.newMeters?.meters?.gas?.number,
          indexes: gasIndexes && this.mapIndexes(gasIndexes),
        },
      },
      step: localForm?.newMeters?.step,
    };
  }

  private static mapMeterType(meterType: MeterType): { meterType: MeterType; isExclusiveNight: boolean } {
    let meterTypeWithoutExcl: MeterType;
    let isExclusiveNight: boolean;
    switch (meterType) {
      case MeterType.MONO_EXC:
        meterTypeWithoutExcl = MeterType.MONO;
        isExclusiveNight = true;
        break;
      case MeterType.BI_EXC:
        meterTypeWithoutExcl = MeterType.BI;
        isExclusiveNight = true;
        break;
      default:
        meterTypeWithoutExcl = meterType;
        isExclusiveNight = false;
    }
    return { meterType: meterTypeWithoutExcl, isExclusiveNight };
  }

  private static mapIndexes(indexes: MoveRegister[]): IndexesUI[] {
    return indexes.map((index) => ({
      timeFrame: index.timeFrame,
      type: index.type,
      value: index.value,
    }));
  }

  // We map object from cuzo backend to send it back to cuzo backend for regstar in another format via the front.
  // This is nonsense
  static mapReadInvoiceDataToBillingInfo(readInvoiceData: ReadInvoiceDataCuzoApi): BillingInfo {
    // Map payment method
    const paymentMethodMap: Record<ReadInvoiceDataPaymentMethodEnumCuzoApi, PaymentMethod> = {
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.BANK_DOMICILIATION]: PaymentMethod.BANK_DOMICILIATION,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.DIRECT_DEBIT]: PaymentMethod.BANK_DOMICILIATION,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.BANK_TRANSFER]: PaymentMethod.BANK_TRANSFER,
      [ReadInvoiceDataPaymentMethodEnumCuzoApi.DIRECT_DEBIT_AND_BANK_TRANSFER]:
        PaymentMethod.BANK_DOMICILIATION_AND_BANK_TRANSFER,
    };

    // Prepare billing info
    const billingInfo: BillingInfo = {
      // Directly mapped fields
      iban: readInvoiceData.bankAccount,
      bic: readInvoiceData.bankAccountBIC,

      // Map payment method
      paymentMethod: readInvoiceData.paymentMethod ? paymentMethodMap[readInvoiceData.paymentMethod] : undefined,

      // Derive electronic invoicing and invoice sending type
      electronicInvoicing: readInvoiceData.invoiceDeliveryChannel === InvoicingMethod.ELECTRONIC,
      invoiceSendingType: readInvoiceData.invoiceDeliveryChannel,

      // Refund account details
      refundIban: readInvoiceData.refundAccount,
      refundBic: readInvoiceData.refundAccountBIC,
    };

    // Map billing method (payment frequency)
    const billingMethodMap: Record<string, BillingMethod> = {
      MONTHLY: BillingMethod.MONTHLY,
      BIMONTHLY: BillingMethod.BIMONTHLY,
      // Add more mappings if needed
    };

    if (readInvoiceData.paymentFrequency) {
      billingInfo.billingMethodType =
        billingMethodMap[readInvoiceData.paymentFrequency.toUpperCase()] || BillingMethod.MONTHLY; // default to monthly if not matched
    }

    // Map address if available
    if (readInvoiceData.address) {
      billingInfo.address = {
        street: readInvoiceData.address.street,
        streetNumber: readInvoiceData.address.streetNumber,
        box: readInvoiceData.address.boxNumber,
        zip: readInvoiceData.address.zipCode,
        city: readInvoiceData.address.city,
        country: readInvoiceData.address.country,
      };
    }

    // Additional fields with somewhat complex mapping
    billingInfo.bankName = undefined; // No direct mapping available
    billingInfo.refundBankName = undefined; // No direct mapping available

    // Flag indicating if invoice sending method is locked
    billingInfo.useDeliveryAddress = readInvoiceData.invoiceSendingMethodLocked ?? false;

    return billingInfo;
  }
}
