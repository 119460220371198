import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavigationComponent } from '../../navigation/navigation.component';
import {
  Contact,
  INITIAL_MOVE_STATE,
  MoveDTO,
  MoveFormFrontend,
  MoveSite,
  MoveState,
} from '@app/core/state/move.state';
import { MoveFormStep } from '../MoveFormStep';
import { filter, Observable, take, tap } from 'rxjs';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { TranslateModule } from '@ngx-translate/core';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { ShowHide } from '@app/shared/utils/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CustomerType } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { FormGroupControls } from '@app/shared/utils/utils.form';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { deepCopy } from '@app/shared/utils/utils.form';

export interface NewOccupant {
  hasNewOccupant: boolean;
  customerType: CustomerType;
  firstName: string;
  lastName: string;
  mobilePhone: string;
}

type NewOccupantForm = FormGroupControls<NewOccupant>;

@Component({
  selector: 'app-new-occupant',
  standalone: true,
  imports: [
    CommonModule,
    NavigationComponent,
    ReactiveFormsModule,
    TranslateModule,
    AlertComponent,
    InputErrorComponent,
  ],
  templateUrl: './new-occupant.component.html',
  styleUrls: ['./new-occupant.component.scss'],
  animations: [ShowHide],
})
export class NewOccupantComponent extends MoveFormStep<MoveDTO> implements OnInit {
  formGroup: FormGroup<NewOccupantForm> = new FormGroup({
    hasNewOccupant: new FormControl(null, Validators.required),
    customerType: new FormControl(null, Validators.required),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    mobilePhone: new FormControl(''),
  });
  registration: MoveInRegistration;
  customerType = CustomerType;
  readonly AlertType = AlertType;
  isFormDisabled = false;
  submittedForm: boolean = false;

  constructor(protected readonly moveFormFacade: MoveFormFacade) {
    super(moveFormFacade);
    this.formGroup
      .get('hasNewOccupant')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe((value: boolean) => {
        if (!this.isFormDisabled) {
          const customerType = this.formGroup.get('customerType');
          value ? customerType.enable() : customerType.disable();
        }
      });
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1),
        tap((state: MoveState) => {
          if (state.registrationNewOccupant) {
            this.registration = state?.registrationNewOccupant;
          }
        })
      )
      .subscribe((state: MoveState) => {
        if (state?.form?.newOccupant) {
          this.formGroup.patchValue(state.form?.newOccupant);
        }
        this.isFormDisabled = !!this.registration?.id;
        // Customer can't change this form after he submitted this step. New registration was created and segment prop can't be changed
        if (this.isFormDisabled) {
          this.formGroup.disable();
        }
      });
  }

  get customerTypeControl() {
    return this.formGroup.get('customerType');
  }

  get hasNewOccupantControl() {
    return this.formGroup.get('hasNewOccupant');
  }

  onPreviousClickedDefault() {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getForm() });
    this.moveFormFacade.previous();
    this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
  }

  onNextClickedDefault() {
    if (this.formGroup.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.updateData({ form: this.getForm() });
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        this.moveFormFacade.next();
      });
    } else if (!this.formGroup.invalid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      this.moveFormFacade.next();
    } else {
      this.submittedForm = true;
      markFormGroupTouched(this.formGroup);
    }
  }

  saveFormData(): Observable<MoveDTO> {
    return this.moveFormFacade.saveNewOccupantRegistrationAndMove(
      this.formGroup.value.hasNewOccupant,
      this.mapFormToMoveIn(this.formGroup.value, this.moveFormFacade.state$.value.moveDTO)
    );
  }

  private getForm(): MoveFormFrontend {
    return {
      ...this.moveFormFacade?.state$?.value?.form,
      newOccupant: {
        ...(this.formGroup.value as NewOccupant),
      },
    };
  }
  private mapFormToMoveIn(occupantNewData: Partial<NewOccupant>, moveOut: MoveDTO): MoveInRegistration {
    const { customerType, hasNewOccupant, ...newData } = { ...occupantNewData };
    const [moveOutSite] = moveOut?.sites || [];
    const moveOutSiteCopy = deepCopy(moveOutSite);
    const mnoveOutAddress = deepCopy(moveOutSiteCopy?.address);
    const moveOutSiteWithOutIds = this.deleteIdFromNestedObject(moveOutSiteCopy) as MoveSite;
    // We need to remove ids of moveout except from address object
    moveOutSiteWithOutIds.address = mnoveOutAddress;
    const contact: Contact = {
      firstName: newData.firstName,
      lastName: newData.lastName,
      mobilePhone: newData.mobilePhone,
    };
    const newRegistration = this.moveFormFacade.buildNewOccupantRegistration(
      customerType,
      contact,
      moveOutSiteWithOutIds
    );
    return newRegistration;
  }

  // We are reusing registration of current customer(ref) and we are updating the contact/company
  // details with new occupant details. Because of that we need to remove all ids from the payload
  private deleteIdFromNestedObject(value: Record<string, any>): Record<string, any> {
    this.deleteIdFromObject(value);
    Object?.keys(value).forEach((key) =>
      this.isObject(value[key])
        ? this.deleteIdFromNestedObject(value[key]) && this.deleteIdFromObject(value[key])
        : null
    );
    return value;
  }

  private isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
  }

  private deleteIdFromObject(value: Record<string, any>): void {
    delete value?.id;
  }
}
