<div [formGroup]="metersFormGroup">
  <div formGroupName="electricity">
    <div class="flex mt-8 items-center mb-8">
      <img ngSrc="./assets/img/icons/standalone/default/electricity.svg" height="30" width="24" />
      <h3 class="text-lg ml-4">{{ 'pages.move.energyType.energy.electricity' | translate }}</h3>
    </div>
    <p>
      {{ 'pages.move.newMeters.electricity.explanation' | translate }}
    </p>
    <section class="border-b border-grey/35 pb-12 mb-12">
      <div class="my-8">
        <p class="form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="noMeter" id="noMeter" />
          <label for="noMeter">
            {{ 'pages.move.newMeters.electricity.noMeterCheckbox' | translate }}
          </label>
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p
          class="w-full sm:w-1/2 form-element input-text"
        >
          <te-input-ean
            [label]="'general.eanCode' | translate"
            formControlName="ean"
            id="ean"
            [errorMessage]="eanErrorTranslations"
          >
          </te-input-ean>
        </p>
        <p
          class="w-full sm:w-1/2 form-element errors input-text mt-4"
          [class.errors]="
            electricityControls.number.invalid &&
            (electricityControls.number.touched || electricityControls.number.dirty)
          "
        >
          <label>{{ 'general.meterNumber' | translate }}</label>
          <input type="text" formControlName="number" placeholder="XXXXXXXX" />
          <app-input-error
            [cssClass]="'mt-2'"
            [condition]="
              electricityControls.number.errors?.required &&
              (electricityControls.number.touched || electricityControls.number.dirty)
            "
            [errorMessage]="'errorMessages.requiredField'"
          ></app-input-error>
        </p>
      </div>
      <div
        [class.errors]="
          electricityControls.smartMeterAndMeterType.invalid &&
          (electricityControls.smartMeterAndMeterType.touched || electricityControls.smartMeterAndMeterType.dirty)
        "
      >
        <app-custom-select
          [field]="{
            id: 'smartMeterAndMeterType',
            label: 'pages.move.meterType' | translate,
            formControl: electricityControls.smartMeterAndMeterType,
            options: meterTypeOptions
          }"
        >
        </app-custom-select>
        <app-input-error
          [cssClass]="'mt-2'"
          [condition]="
            electricityControls.smartMeterAndMeterType.errors?.required &&
            (electricityControls.smartMeterAndMeterType.touched || electricityControls.smartMeterAndMeterType.dirty)
          "
          [errorMessage]="'errorMessages.requiredField'"
        ></app-input-error>
      </div>
      <div class="sm:flex gap-8 mb-8">
        <p class="w-full form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="exclusiveNight" id="exclusiveNight" />
          <label for="exclusiveNight">
            {{ 'pages.move.newMeters.electricity.exclusiveNightCheckbox' | translate }}</label
          >
        </p>
      </div>

      <div
        *ngIf="region === Region.flanders && electricityControls.smartMeter.value"
        class="form-element input-text has-suffix max-w-96"
        [class.errors]="
          electricityControls.peakPower.invalid &&
          (electricityControls.peakPower.touched || electricityControls.peakPower.dirty)
        "
      >
        <label for="peakPower"> {{ 'pages.move.newMeters.electricity.peakPowerField' | translate }} </label>
        <div class="flex">
          <input id="peakPower" type="text" placeholder="0" formControlName="peakPower" />
          <div class="suffix">{{ 'general.units.kVA' | translate }}</div>
        </div>
        <app-input-error
          [cssClass]="'mt-4'"
          [condition]="
            electricityControls.peakPower.invalid &&
            (electricityControls.peakPower.touched || electricityControls.peakPower.dirty)
          "
          [errorMessage]="'errorMessages.requiredField'"
        ></app-input-error>
      </div>
    </section>

    <section class="">
      <app-expandable-checkbox-btn
        [name]="'hasSolarPanels'"
        [value]="electricityControls.hasSolarPanels.value"
        [selectedValue]="electricityControls.hasSolarPanels.value"
        (selectedValueChange)="onHasSolarPanelChanges($event)"
      >
        <div class="flex items-center" label>
          {{ 'pages.move.newMeters.electricity.solarPanelsCheckbox' | translate }}
        </div>

        <div
          class="content relative -ml-[-26px] -mr-[-26px] pt-0"
          [class.expand]="electricityControls.hasSolarPanels.value"
          [class.loading]="!electricityControls.hasSolarPanels.value"
        >
          <div class="sm:flex gap-8 mb-8">
            <div
              class="w-full form-element input-text has-suffix"
              [class.errors]="submittedForm && electricityControls.solarPanelsKva.invalid && solarPanelsKvaInteracted"
            >
              <label for="solarPanelsKva">
                {{ 'pages.move.newMeters.electricity.solarPanelsKvaField' | translate }}
              </label>
              <div class="flex">
                <input
                  id="solarPanelsKva"
                  type="number"
                  formControlName="solarPanelsKva"
                  [placeholder]="solarPanelKvaRange.min"
                  style="padding-top: 14px; padding-bottom: 14px"
                />
                <div class="suffix">KVA</div>
              </div>
              <app-input-error
                [condition]="submittedForm && solarPanelsKvaControl.errors?.required && solarPanelsKvaInteracted"
                [errorMessage]="'errorMessages.requiredField'"
              ></app-input-error>

              <app-input-error
                [condition]="
                  submittedForm &&
                  (solarPanelsKvaControl.errors?.min || solarPanelsKvaControl.errors?.max) &&
                  solarPanelsKvaInteracted
                "
                [translatedErrorMessage]="
                  'errorMessages.solarPanelsRange'
                    | translate : { min: solarPanelKvaRange.min, max: solarPanelKvaRange.max }
                "
              ></app-input-error>
            </div>
          </div>
          <div *ngIf="region === Region.wallonia && electricityControls.smartMeter.value" class="sm:flex gap-8 mb-8">
            <div class="w-full sm:w-1/2 form-element input-checkbox mt-4">
              <input id="injectionTariff" type="checkbox" formControlName="injectionTariff" />
              <label for="injectionTariff">
                {{ 'pages.move.newMeters.electricity.injectionTariffCheckbox' | translate }}
              </label>
            </div>
            <div class="w-full sm:w-1/2">
              <p class="form-element input-radio mt-4">
                <input
                  id="before2024"
                  type="radio"
                  formControlName="solarPanelsInstallationDateStarting2024"
                  [value]="false"
                />
                <label for="before2024"> {{ 'pages.move.newMeters.electricity.before2024Option' | translate }} </label>
              </p>
              <p class="form-element input-radio mt-4">
                <input
                  id="after2024"
                  type="radio"
                  formControlName="solarPanelsInstallationDateStarting2024"
                  [value]="true"
                />
                <label for="after2024"> {{ 'pages.move.newMeters.electricity.after2024Option' | translate }} </label>
              </p>
            </div>
          </div>
        </div>
      </app-expandable-checkbox-btn>
    </section>

    <section *ngIf="electricityControls.indexes?.length" class="border-t border-grey/35 pt-12 mt-12">
      <div class="md:flex flex-wrap indexes">
        <div
          class="w-full md:w-1/2 overflow-hidden"
          *ngFor="let indexControl of electricityControls.indexes?.['controls']"
        >
          <app-input-meter-index
            [style]="InputMeterStyle.INLINE"
            [type]="indexControl?.controls.type?.value"
            [value]="indexControl?.controls.value?.value"
            [control]="indexControl"
            [newType]="indexControl.controls.timeFrame.value"
            [unit]="'pages.move.myMeters.unit.ELECTRICITY' | translate"
            [label]="
              'pages.move.myMeters.meterInput.' +
                indexControl.controls.timeFrame.value +
                '.' +
                indexControl.controls.type.value | translate
            "
          >
          </app-input-meter-index>
        </div>
      </div>
    </section>
  </div>
</div>
