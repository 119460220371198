<h2 *ngIf="moveSites.length === 0">{{ 'components.move.status.noSiteWithActiveContract' | translate }}</h2>
<div *ngFor="let moveSite of moveSites">
  <app-no-move
    *ngIf="moveSite?.viewStatus === ViewStatus.NO_MOVE"
    [moveSite]="moveSite"
  >
  </app-no-move>
  <app-move-summary
    *ngIf="moveSite?.viewStatus !== ViewStatus.NO_MOVE"
    [moveSite]="moveSite"
  >
  </app-move-summary>
</div>
