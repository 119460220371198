<section class="mb-12">
  <div class="head flex items-end mb-4">
    <h3 class="text-xl">{{ 'pages.move.newOccupant.title' | translate }}</h3>
    <span class="text-primary font-bold cursor-pointer ml-auto text-sm" (click)="edit()">{{
      'pages.move.finalize.editButton' | translate
    }}</span>
  </div>
  <div class="grid sm:grid-cols-2 grid-cols-1 gap-4 bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div class="flex-1 mb-4 sm:mb-0">
      <h4 class="text-sm font-medium">{{ 'pages.move.finalize.summaryNewOccupant.buyerKnown' | translate }}</h4>
      <span class="text-sm">
        {{ 'pages.move.newOccupant.questions.hasNewOccupant.' + (knowBuyer ? 'yes' : 'no') | translate }}
      </span>
      <ng-container *ngIf="knowBuyer">
        <h4 class="text-sm font-medium mt-4">
          {{ 'pages.move.finalize.summaryNewOccupant.contractType' | translate }}
        </h4>
        <span class="text-sm">
          {{
            'pages.move.newOccupant.questions.customerType.' +
              (registration?.segment === CustomerType.b2c ? 'residential' : 'professional') | translate
          }}
        </span>
      </ng-container>
    </div>
    <ng-container
      *ngIf="
        knowBuyer &&
        (registration?.contact?.firstName || registration?.contact?.lastName || registration?.contact?.mobilePhone)
      "
    >
      <div class="flex-1">
        <h4 class="text-sm font-medium">{{ 'pages.move.finalize.summaryNewOccupant.infos' | translate }}</h4>
        <span class="text-sm"> {{ registration?.contact?.firstName }} {{ registration?.contact?.lastName }} </span>
        <span class="block text-sm mt-1">
          {{ registration?.contact?.mobilePhone }}
        </span>
      </div>
    </ng-container>
  </div>
</section>
